import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ProjectContext from 'src/components/ProjectContext';



import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";

export default function EditItemNotesDialog(skuForRow) {
  const [open, setOpen] = React.useState(false);
  const projectId = React.useContext(ProjectContext)


  const [notesValues, setNotesValues] = React.useState({
    notes: '',
  });

  const handleClickOpen = () => {

    var db = firebase.firestore();

    db.collection(`customer/${projectId}/inventory_raw_goods`).doc(skuForRow.value).get()
    .then(function(doc) {

        if (doc.exists) {
            var jsonData = doc.data();
            console.log("jsonData.notes: ",jsonData.notes)
            setNotesFunction(jsonData.notes)
        }
        

        console.log("Document successfully read!");
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });


    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setNotesFunction = (data) => {
    setNotesValues({notes: data})
  }

  const handleAddItem = (formData) => {

    var db = firebase.firestore();

    db.collection(`customer/${projectId}/inventory_raw_goods`).doc(skuForRow.value).update({
      "notes": notesValues.notes,
    })
    .then(function() {
        console.log("Document successfully written!");
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });


    setOpen(false);
    
  };
  
  

  return (
    <div>
      
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Edit Notes
      </Button>
      
      
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth = {'md'}>
        <DialogTitle id="form-dialog-title">Notes</DialogTitle>
          <DialogContent>

            <TextField            
              margin="dense"
              id="notes"
              label="Notes"
              value={notesValues.notes}
              type="note"
              multiline={true}
              rows={4}
              variant="filled"
              fullWidth
              onChange={(event) => setNotesFunction(event.target.value)} 
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddItem} color="primary">
              Save
            </Button>
          </DialogActions>
          {/* </form> */}
          
        </Dialog>
        
    </div>
  );
}
