import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import {
    Avatar,
    Box,
    Grid,
    Button,
  } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import ProjectContext from 'src/components/ProjectContext';

/*const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    backgroundColor: 'green', //colors.green[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: 'green'//colors.green[900]
  },
  differenceValue: {
    color: 'green',
    marginRight: 10 //theme.spacing(1)
  }
});*/

class TotalProfit extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            totalPurchasePrice: 0,
            totalItemsRemoved: 0,
            expanded: false,
            curTime : new Date(),
        };
        this.handleExpandClick = this.handleExpandClick.bind(this)
        this.refreshButtonClick = this.refreshButtonClick.bind(this)
    }

    handleExpandClick() {
        this.setState({expanded: !this.state.expanded});
    }

    refreshButtonClick(){
      console.log("Refhresh clicked")
      
      var db = firebase.firestore();
        const projectId = this.context;
    
        var refs = db.doc(`customer/${projectId}/inventory_raw_goods/CRCEB`);
      
        var callback = this.promise;
    
        refs.get(callback)
    
        var promise = new Promise( (resolve) => {
        

            db.collection(`customer/${projectId}/inventory_raw_goods`).get()
          .then(function(snapshot)
          {        
    
            resolve(snapshot)
    
          }).catch(function(error) {
                console.error("Error writing document: ", error);
          });
        });
        
        promise.then( result => {        
            var collectionsDocData = result.docs.map(doc => {
            
              return {
                name: doc.id,
                data: doc.data()
              }
            });
    
            console.log("collectionsDocData: ",collectionsDocData)

            this.setState({collectionsDocDataState: collectionsDocData})
    
    
        }, function(error) {
            console.log("Error")
        });
    }

    componentDidMount() {

      var db = firebase.firestore();
      var projectId = this.props.projectId;
      var refs = db.doc(`customer/${projectId}/salesReport/online`);
      var callback = this.promise;
  
      refs.get(callback)
  
      var promise = new Promise( (resolve) => {
      
  
        db.collection(`customer/${projectId}/salesReport`).get()
        .then(function(snapshot)
        {        
  
          console.log("snapshot.docs: ",snapshot.docs)
          resolve(snapshot)
  
        }).catch(function(error) {
              console.error("Error writing document: ", error);
        });
      });
      
      promise.then( result => {        

        console.log("result.docs", result.docs)

          var collectionsDocData = result.docs.map(doc => {

          
            return {
              name: doc.id,
              data: doc.data()
            }
          });
  
          console.log("about to set state")

          this.setState({collectionsDocDataState: collectionsDocData})
  
          console.log("done setting state")
  
      }, function(error) {
          console.log("Error")
      });
    }
    

    render() {

      // console.log("this.state.curTime.getTime() /1000", this.state.curTime.getTime()/1000)

      // var quantitySum = 0
      // var subTotalSum = 0


      // var transactionTables = []
      // if(this.state.collectionsDocDataState)
      // {
      //   console.log("In!!!!!")

      //   console.log("this.state.collectionsDocDataState: ",this.state.collectionsDocDataState)

      //   this.state.collectionsDocDataState.map(collection => 
      //   {
      //     console.log("In!!!!! Again")
      //     var transactionItemsFilteredOnDateRange = []

      //     console.log("this.state.curTime.getTime() / 1000", this.state.curTime.getTime()/1000)
      //     console.log("collection.data.transactions[i].timeStamp.seconds: ",collection.data.transactions[i].timeStamp.seconds)

      //     for(var i = 0; i < collection.data.transactions.length; i++)
      //     {
      //       if((this.state.curTime.getTime() / 1000) < collection.data.transactions[i].timeStamp.seconds 
      //       &&  collection.data.transactions[i].timeStamp.seconds < (this.state.curTime.getTime()/1000))
      //       {
      //         transactionItemsFilteredOnDateRange.push(collection.data.transactions[i])
      //         quantitySum += collection.data.transactions[i].quantity
      //         subTotalSum += collection.data.transactions[i].subTotal
      //       }
      //     }
      //   })
      // }

      
        return (
            <Card >
              <CardContent>
                <Grid
                container
                justify="space-between"
                spacing={3}
                >
                <Grid item>
                    <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                    >
                    SALES SNAPSHOT within the last 7 days
                    </Typography>
                    <Typography
                    color="textPrimary"
                    variant="h3"
                    >
                        ${this.state.totalPurchasePrice}
                    </Typography>
                </Grid>
                <Grid item>
                    <Avatar >
                      <WhatshotIcon/>
                    </Avatar>
                </Grid>
                </Grid>
                <Box
                mt={2}
                display="flex"
                alignItems="center"
                >
                <ArrowUpwardIcon />
                <Typography   
                    variant="body2">
                    {this.state.totalItemsRemoved}
                </Typography>
                <Typography
                    color="textSecondary"
                    variant="caption"
                >
                    Items sold
                </Typography>
                </Box>
                <Button variant="contained" color="primary" onClick={this.refreshButtonClick}>
                  Refresh
                </Button>
                </CardContent>
            </Card>
          )
    }
}
TotalProfit.contextType = ProjectContext;

export default TotalProfit;
