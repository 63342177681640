import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Container,
  makeStyles,
  TextField,
  Button,
  Paper,
  Grow
} from '@material-ui/core';
import Page from 'src/components/Page';
import { Link as RouterLink, useNavigate } from '@reach/router';
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import {loadStripe} from '@stripe/stripe-js';

import Typography from '@material-ui/core/Typography';
import { withFirebase } from 'src/components/Firebase';
import ProjectContext from 'src/components/ProjectContext';
import {withAuthentication, AuthUserContext } from 'src/components/Session';
import {withPayment, PaymentContext } from 'src/components/PaymentContext';
import { css } from "@emotion/react";
import PropagateLoader from "react-spinners/PropagateLoader";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import HashLoader from "react-spinners/HashLoader";
import DotLoader from "react-spinners/DotLoader";



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    align: 'center',
    alignContent: 'center',
    // msAlignSelf: 'center',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  container: {
    display: 'flex',
    align: 'center',
  },
  paper: {
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
}));


// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;




const CreateProjectView = (props) => {


  const classes = useStyles();
  const projectId = useContext(ProjectContext);
  const navigate = useNavigate();


  console.log("props: ", props)


const [projectName, setProjectName] = React.useState('');
const [loading, setLoading] = useState(false)


async function proceedToCheckout (user, projectId) {


  console.log("projectId: ", projectId)

  console.log("checkout clicked", user)


  // console.log("user: ", val)

  var db = firebase.firestore();

  const docRef = await db
  .collection('customer')
  .doc(projectId)
  .collection('checkout_sessions')
  .add({
    price: 'price_1InxCHAssoeWWRHKVtKVm2X4',
    allow_promotion_codes: true,
    success_url: window.location.origin + `/app/${projectId}/inventory`,
    cancel_url: window.location.origin,
  });


  console.log("docRef!!!!!!: ",docRef)



  // [...]
  // Wait for the CheckoutSession to get attached by the extension
  docRef.onSnapshot(async (snap) => {
    const { error, sessionId } = snap.data();

    console.log("snap.data(): ",snap.data())

    if (error) {
      // Show an error to your customer and 
      // inspect your Cloud Function logs in the Firebase console.
      alert(`An error occured: ${error.message}`);
    }
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      // Init Stripe

      console.log("Init Stripe: ",sessionId)

      // const stripe = await loadStripe('pk_test_51IgKivAssoeWWRHK8dKHlPKLhCmYIW3kM1hfffFsyFlMoaAf6mlWSiT9nmJKOpzPPYMGhQAFWNNWN3XlkNrUsSR400UTxhjraI');
      const stripe = await loadStripe('pk_live_51IgKivAssoeWWRHKmk76eg6XchLKHTOfEw5FaNxMMTsr1Z8LIxvKVcKLPiYfF98OQfyIQF50gJKjSBScGUfZ0mSF00e4YWlFp3');


      console.log("Init Stripe: ",stripe)
      


      stripe.redirectToCheckout({ sessionId }).then(function(result) {

        console.log("Checkout success: ", result)

        // If `redirectToCheckout` fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using `error.message`.

        if (result.error) {

          console.log("Checkout failed: ")
          alert(result.error.message);
        }
      });
    }
  });

}

const handleInput = (e) => {

  console.log("e: ", e)

    setProjectName(e.target.value);
  };
  

  const createNewProject = () => {
      

    setLoading(true)
    

    props.firebase.createNewProject(projectName).then( response =>{        
        

      firebase.auth().currentUser.getIdTokenResult()
    .then((idTokenResult) => {
      // Confirm the user is an Admin.
      console.log("idTokenResult: ",idTokenResult)
      console.log("idTokenResult.claims: ",idTokenResult.claims)
      console.log("idTokenResult.claims.admin: ",idTokenResult.claims.stripeRole)


      console.log("idTokenResult.claims.user_id: ",)



      proceedToCheckout(idTokenResult.claims.user_id,response.id)

      firebase.auth().currentUser.getIdTokenResult()
      .then((idTokenResult) => {
      // Confirm the user is an Admin.

      

      if (!!idTokenResult.claims.stripeRole) {
        // Show admin UI.
        // showAdminUI();
        console.log("showAdminUI")
      } else {
        // Show regular user UI.
        // showRegularUI();
        console.log("showRegularUI")
      }
    })
    .catch((error) => {
      console.log(error);
    });
      

      if (!!idTokenResult.claims.stripeRole) {
        // Show admin UI.
        // showAdminUI();
        console.log("showAdminUI")
      } else {
        // Show regular user UI.
        // showRegularUI();
        console.log("showRegularUI")
      }
    })
    .catch((error) => {
      console.log(error);
    });


    
    })
    
  }

  return (
    <Page
      className={classes.root}
      title="Inventory Simplified"
    >
      {/* <PaymentContext.Consumer>
        {payingSubscriber =>  */}
          <Container maxWidth={false}>
              <Box> 
                  <Box mt={20} align='center'>
                      <Typography variant="h3" component="h3" gutterBottom align='center'>
                          New Project
                      </Typography>
                      <TextField 
                          onChange={handleInput}
                          // onSubmit={handleInput}
                          id="standard-basic" label="Project Name"  align='center'/>
                  </Box>

                  
                  <Box mt={3} align='center'>
                    {loading ? 
                      <div className="sweet-loading">
                        <Box mt={3} align='center'>
                          <Typography variant="subtitle1" component="h4" gutterBottom align='center'>
                              Setting up your project...
                          </Typography>
                        </Box>
                        <Box mt={3} align='center'>
                          <DotLoader css={override} size={60} color={"#0d3c69"} loading={loading} speedMultiplier={1.5} />
                        </Box>
                      </div>
                    : 
                      <Button variant="contained" 
                              color="primary" 
                              align='center' 
                              paddingY={20}
                              onClick={() => createNewProject()}
                              >Create</Button>
                    }
                      
                  </Box>
              </Box>
            </Container>
         {/* } */}
        
      
       

      {/* </PaymentContext.Consumer> */}
    </Page>
  );
};


export default withFirebase(CreateProjectView);
