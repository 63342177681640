import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {

  constructor() {

    if(!app.apps.length){
      app.initializeApp(config);
    }

    this.auth = app.auth();
    this.firestore = app.firestore();
    this.functions = app.functions();
    this.storage = app.storage();
    this.user = null;

    // Currently selected project.
    this.currentProject = {};

    /*this.listener = this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.getUser(authUser.uid).then(user => {
          this.user = user;
        });
      }
      else {
        this.user = null;
      }
    });*/

    this.userRoles = [
      { name: 'Admin', dbProperty: 'admin' },
      { name: 'User', dbProperty: 'user' },
    ];
  }

  setRole(uid, role, projectId) {
    const projectDocRef = this.firestore.doc(`customer/${projectId}`);

    return this.firestore.runTransaction(transaction => {
      return transaction.get(projectDocRef).then(docSnapshot => {
        if (!docSnapshot.exists) {
          throw new Error(`Document 'customer/${projectId}' doesn't exist`);
        }

      });
    });
  }

  createNewProject(name) {

    console.log("Inside the firebase call create new project: ", name)
    console.log(this.auth.currentUser.uid)

    return this.firestore.collection(`customer`).add({
      name: name,
      payingUser: this.auth.currentUser.uid,
      admins: [this.auth.currentUser.uid],
    }).then(response => {

      // console.log('Added document with ID: ', response);
      this.setProject(response.id, name)
      return response
    });
  }


  setProject(id, name) {
    this.currentProject.id = id;
    this.currentProject.name = name;
  }

  getUsers(projectId) {
    const getUsersFunction = this.functions.httpsCallable('getUsers');
    return getUsersFunction({ "customerId": projectId })
      .then(response => {
        const usersArr = response.data.users.map(user => ({...user, role: 'User'}));
        const adminsArr = response.data.admins.map(admin => ({...admin, role: 'Admin'}));
        const pendingUsersArr = response.data.pendingUsers.map(pendingUser => ({...pendingUser, pending: true}));
        return usersArr.concat(adminsArr, pendingUsersArr);
      });
  }

  addUserToProject(projectId, email, newRole) {
    var pendingUserEmail;
    return this.getUsers(projectId)
      .then(users => {
        const docRef = this.firestore.doc(`customer/${projectId}`);
        return this.firestore.runTransaction(transaction => {
          return transaction.get(docRef).then(doc => {
            if (!doc.exists) {
              throw new Error(`Project with id ${projectId} doesn't exist`);
            }

            const data = doc.data();
            let pendingUsers = []
            if (data.pendingUsers) {
              pendingUsers = data.pendingUsers;
            }
            if (!pendingUsers.includes(email)) {
              pendingUsers.push({
                id: email,
                role: newRole
              });
              transaction.update(docRef, { 'pendingUsers': pendingUsers });
            }
            pendingUserEmail = email;
            const sendSignUpEmailFunction = this.functions.httpsCallable('sendSignUpEmail');
            return sendSignUpEmailFunction({ projectId: projectId, email: email });
          });
        })
      })
      .then(response => {
        return pendingUserEmail;
      });
  }

  getUser(userId) {
    return this.firestore.doc(`users/${userId}`).get().then((docSnapshot) => {
      const user = docSnapshot.data(); 
      user.id = docSnapshot.id;
      return user;
    })
  }

  getProjects(userId) {
    const getProjectsFunction = this.functions.httpsCallable('getProjects');
    return getProjectsFunction({ "userId": userId }).then(projectIds => {
      if (projectIds.data.length > 0) {
        return this.firestore.collection('customer').where('__name__', 'in', projectIds.data).get()
      }
      else {
        throw new Error('User does not have access to any projects')
      }
    })
      .then(querySnapshot => {
        const docs = [];
        querySnapshot.forEach(docSnapshot => {
          const projectObject = docSnapshot.data();
          projectObject.id = docSnapshot.id;
          docs.push(projectObject);
        });
        return docs;
      });
  }

  postSignIn(isNewUser, uid, email, firstName, lastName) { 
    if (isNewUser) {
      const doc  = {
        email: email,
        createdAt: app.firestore.FieldValue.serverTimestamp()
      };
      if (firstName && lastName) {
        doc.first_name = firstName;
        doc.last_name = lastName;
      }
      return app.firestore().collection('users').doc(uid).set(doc).then((resp) => {
          return resp;
        });
    }
    else {
      return app.firestore().collection('users').doc(uid).get()
        .then(docSnapshot => {
          if (!docSnapshot.exists) {
            throw `Cannot find user ${uid} in list of users.`;
          }
          return docSnapshot.data();
        });
    }
  };

  signInWithPopup(provider) {
    return this.auth.signInWithPopup(provider).then(result => {
      return this.postSignIn(
        result.additionalUserInfo.isNewUser,
        result.user.uid, 
        result.user.email,
        result.additionalUserInfo.profile.given_name,
        result.additionalUserInfo.profile.family_name);
    }).catch(error => {
      console.error("Sign in with popup ", error.message);
      throw new Error(error.message);
    });
  }

  doGoogleSignInWithPopup(joinProjectId, joinId) {
    let userCredentialPromise = null;

    // If this user is attempting to join this project
    if (joinProjectId && joinId) {
      userCredentialPromise = this.signInWithPopup(new app.auth.GoogleAuthProvider())
        .then(user => {
          const joinProjectFunction = this.functions.httpsCallable('joinProjectById');
          return joinProjectFunction({ "projectId": joinProjectId, "joinId": joinId })
        })
        .catch(error => {
          throw new Error(error);
        });
    }
    //If this is a normal user login (not joining a project).
    else {
      userCredentialPromise = this.signInWithPopup(new app.auth.GoogleAuthProvider());
    }
    return userCredentialPromise;
  }

  doFacebookSignInWithPopup() {
    return "Not Supported"
  }

  doEmailSignUp(settingsObject, joinProjectId, joinId) {
    return this.auth.createUserWithEmailAndPassword(settingsObject.email, settingsObject.password)
      .then(userCredential => {
        return this.postSignIn(
          userCredential.additionalUserInfo.isNewUser,
          userCredential.user.uid, 
          userCredential.user.email,
          settingsObject.firstName,
          settingsObject.lastName);
      })
      .then(user => {
        if (joinProjectId && joinId ) {
          const joinProjectFunction = this.functions.httpsCallable('joinProjectById');
          return joinProjectFunction({ "projectId": joinProjectId, "joinId": joinId });
        }
        else {
          return user;
        }
      });
  }

  doEmailSignIn(email, password, joinProjectId, joinId) {
    return this.auth.signInWithEmailAndPassword(email, password)
      .then(result => {
        return this.postSignIn(
          result.additionalUserInfo.isNewUser,
          result.user.uid, 
          result.user.email);
      })
      .then(user => {
        if (joinProjectId && joinId ) {
          const joinProjectFunction = this.functions.httpsCallable('joinProjectById');
          return joinProjectFunction({ "projectId": joinProjectId, "joinId": joinId });
        }
        else {
          return user;
        }
      });
  }

  doSignOut() {
    return app.auth().signOut();
  }

}

export default Firebase;
