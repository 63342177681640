import React from 'react';
import { Link as RouterLink, navigate } from '@reach/router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Box,
  makeStyles,
  IconButton, 
  Typography,
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import InputIcon from '@material-ui/icons/Input';
import { withFirebase } from 'src/components/Firebase';

const useStyles = makeStyles(({
  root: {},
  toolbar: {
    height: 64,
    color: "#3f90ec",
  }
}));

const TopBar = ({ className, firebase, ...rest }) => {
  const classes = useStyles();

  const handleLogout = () => {
    firebase.doSignOut()
      .then(function() {
        navigate('/login');
      });
  }

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <IconButton color="inherit" onClick={handleLogout}>
          {/* <InputIcon /> */}
          <Typography variant="button" display="block"  gutterBottom>Sign Out</Typography>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  firebase: PropTypes.object,
};

export default withFirebase(TopBar);
