import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ThemeProvider } from '@material-ui/core';
import theme from 'src/theme';
import { Router } from '@reach/router';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import CreateProjectView from 'src/views/createProject'
import AccountView from 'src/views/account/AccountView';
import UserManagementView from 'src/views/account/UserManagementView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import ProductListView from 'src/views/product/ProductListView';
import SettingsView from 'src/views/settings/SettingsView';
import RegisterView from 'src/views/auth/RegisterView';
import Firebase, { FirebaseContext } from 'src/components/Firebase';
import ProjectSelectionView from 'src/views/project-selection';
import SKUDetailsView from 'src/views/customer/SKUDetailsView'

const App = () => {

  return ( 
    <ThemeProvider theme={theme}>
      <FirebaseContext.Provider value={new Firebase()}>
        <Router>
          <MainLayout path="/" >
            <ProjectSelectionView path="/" />
            <LoginView path="login"/>
            <RegisterView path="register" />
            <CreateProjectView path="createProject"/>
            <DashboardLayout path="app/:projectId" >
              <AccountView path="account"/>
              <CustomerListView path="inventory"/>
              <SKUDetailsView path=":uid"></SKUDetailsView>
              <DashboardView path="dashboard"/>
              <ProductListView path="products"/>
              <SettingsView path="settings"/>
              <UserManagementView path="user-management"/>
            </DashboardLayout>
          </MainLayout>
        </Router>
      </FirebaseContext.Provider>
    </ThemeProvider>
  );
}

export default App;
