import React, { useContext,useEffect, useState }  from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Box,
  Typography,
  Button,
  TextField,
  MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import ProjectContext from 'src/components/ProjectContext';
import ProjectList from '../../project-selection/ProjectList';
import {withAuthentication, AuthUserContext } from 'src/components/Session';
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import {Elements, CardElement, ElementsConsumer} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { CardContent } from '@material-ui/core';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import SyncLoader from "react-spinners/SyncLoader";
import HashLoader from "react-spinners/HashLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import PacmanLoader from "react-spinners/PacmanLoader";

import {
  Card,
} from '@material-ui/core';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

let projectID

class CheckoutForm extends React.Component {

  handleSubmit = async (event) => {
    event.preventDefault();
    const {stripe, elements} = this.props;
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
  };

  render() {
    const {stripe} = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <CardElement />
        <button type="submit" disabled={!stripe}>
          Pay
        </button>
      </form>
    );
  }
}

const InjectedCheckoutForm = () => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <CheckoutForm stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);




// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_test_51IgKivAssoeWWRHK8dKHlPKLhCmYIW3kM1hfffFsyFlMoaAf6mlWSiT9nmJKOpzPPYMGhQAFWNNWN3XlkNrUsSR400UTxhjraI');
const stripePromise = loadStripe('pk_live_51IgKivAssoeWWRHKmk76eg6XchLKHTOfEw5FaNxMMTsr1Z8LIxvKVcKLPiYfF98OQfyIQF50gJKjSBScGUfZ0mSF00e4YWlFp3');





async function onCustomerPortalClicked (user) {

  const functionRef = firebase
    .app()
    .functions('us-central1')
    .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
  const data = await functionRef({ returnUrl: window.location.origin + window.location.pathname, projectId: projectID });

  window.location.assign(data.data.url);
  // window.location.assign(data.url);
}

async function onCheckoutClicked (user) {

  console.log("checkout clicked", user)

  console.log("projectID", projectID)
  

  // console.log("user: ", val)

  var db = firebase.firestore();

  const docRef = await db
  .collection('customer')
  .doc(projectID)
  .collection('checkout_sessions')
  .add({
    // uid: user.id,
    price: 'price_1InxCHAssoeWWRHKVtKVm2X4',
    allow_promotion_codes: true,
    success_url: window.location.origin + `/app/${projectID}/account`,
    cancel_url: window.location.origin,
  });

  // [...]
  // Wait for the CheckoutSession to get attached by the extension
  docRef.onSnapshot(async (snap) => {
    const { error, sessionId } = snap.data();
    if (error) {
      alert(`An error occured: ${error.message}`);
    }
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      // Init Stripe
      // const stripe = await loadStripe('pk_test_51IgKivAssoeWWRHK8dKHlPKLhCmYIW3kM1hfffFsyFlMoaAf6mlWSiT9nmJKOpzPPYMGhQAFWNNWN3XlkNrUsSR400UTxhjraI');
      const stripe = await loadStripe('pk_live_51IgKivAssoeWWRHKmk76eg6XchLKHTOfEw5FaNxMMTsr1Z8LIxvKVcKLPiYfF98OQfyIQF50gJKjSBScGUfZ0mSF00e4YWlFp3');
      stripe.redirectToCheckout({ sessionId });
    }
  });

}




const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = (props) => {
  const classes = useStyles();
  const projectId = useContext(ProjectContext);

  projectID = projectId

  const [loading, setLoading] = useState(false)
  const [activeSubscription, setActiveSubscription] = useState("");
  const [isActiveSubscription, setIsActiveSubscription] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState("");
  const [currentUserId, setCurrentUserId] = useState();
  const [payingUser, setPayingUser] = useState();
  const [payingUserName, setPayingUserName] = useState();
  const [payingUserId, setPayingUserId] = useState("");
  const [effectivePayingUserId, setEffectivePayingUserId] = useState("");
  const [projectAdmins, setProjectAdmins] = useState([]);

  function onManageClicked (user) {
    setLoading(true)
    onCustomerPortalClicked(user)
  }

  const handlePayingUserChange = (event) => {
    setPayingUserId(event.target.value)
  }

  const handleSavePayingUser = () => {
    var db = firebase.firestore();

    db.collection('customer')
      .doc(projectId)
      .update({ payingUser: payingUserId })
      .then(() => {
        console.log("Successfully set paying user");
        setEffectivePayingUserId(payingUserId);
      });
  }

  function onEnableClicked (user) {
    setLoading(true)
    onCheckoutClicked(user)
  }

  // This effect is for keeping the "isActiveSubscription" variable up to date.
  useEffect(() => {
    var db = firebase.firestore();

    db.collection('customer')
      .doc(projectID)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot((result) => {
        if (result.empty) {
          setIsActiveSubscription(false)
        }
        else{
          setIsActiveSubscription(true)
        }
      });
  }, [isActiveSubscription]);

  // This useEffect watches what the current user is.
  useEffect(() => {
    if (props.firebase.auth.currentUser) {
      setCurrentUserId(props.firebase.auth.currentUser.uid);
    }
  }, [props.firebase.auth.currentUser]);

  // This effect is for keeping the "payingUser" state variable up to date.
  useEffect(() => {
    var db = firebase.firestore();
    db.collection('customer')
      .doc(projectId)
      .get()
      .then(snapshot => {
        if(!snapshot.exists) {
          throw `Project with ID ${projectId} doesn't exist`;
        }
        const data = snapshot.data();
        if (data.admins.includes(props.firebase.auth.currentUser.uid)) {
          const payingUserId = data.payingUser
          const getUsersFunction = props.firebase.functions.httpsCallable('getUsers');
          getUsersFunction({ "customerId": projectId })
            .then(response => {
              let payingUserResponse = response.data.admins.find(a => a.id == payingUserId);
              let projectAdminsResponse = response.data.admins.filter(a => a.first_name && a.last_name) ;
              setProjectAdmins(projectAdminsResponse);
              setPayingUser(payingUserResponse);
              setPayingUserName(payingUserResponse.first_name + ' ' + payingUserResponse.last_name);
              setPayingUserId(payingUserResponse.id);
              setEffectivePayingUserId(payingUserResponse.id)
            });
        }
      });
  }, [effectivePayingUserId]);

  return (
    <AuthUserContext.Consumer>
      {user => 
        
        <Container maxWidth="lg">
          Account
          <h1>Payment</h1>
          <h4>You must be signed in as the billing user in order to make changes to the subscription</h4>
          <Typography variant="h4" component="h4" >
            {activeSubscription}
          </Typography>
          <Typography variant="h3" component="h3" >
            {subscriptionDetails}
          </Typography>
          
          <Grid container direction="row">
            <Grid item xs>
              <Typography variant="h3" component="h3" >
                Billing User For This Project: {user ? user.first_name : ""} {user ? user.last_name : ""}
              </Typography>
            </Grid>
            {projectAdmins.length > 0 ? 
              <Grid item xs>
              
              <TextField
                id="standard-select"
                helperText="Select Billing User"
                label=""
                onChange={handlePayingUserChange}
                select
                value={payingUserId}>
                {projectAdmins.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.first_name + ' ' + option.last_name}
                  </MenuItem>
                ))}
              </TextField>
              {/* <Button onClick={handleSavePayingUser} variant="contained" color="primary">
                Save
              </Button> */}
            </Grid>
            :
            <div></div>
            }
            
          </Grid>

          {effectivePayingUserId === currentUserId ?
            <Box flexDirection={'row'}>
              {isActiveSubscription ?  
                <Box textAlign='center' padding={10}>
                  {loading ? 
                    <div className="sweet-loading">
                      <h3>Retreiving Subscription Info</h3>
                      <h5>This could take up to a few mins</h5>
                      <PropagateLoader css={override} size={15} color={"#0d3c69"} loading={loading} speedMultiplier={1.5} />
                    </div>
                  :
                    <Button variant="contained" color="primary" onClick={() => onManageClicked(user)}>Manage Subscription</Button>
                  }
                  
                </Box>
                :
                <Box textAlign='center' padding={10}>
                  {loading ?
                    <div className="sweet-loading">
                      <h3>Preparing Subscription Info</h3>
                      <h5>This could take up to a few mins</h5>
                      <PropagateLoader css={override} size={15} color={"#0d3c69"} loading={loading} speedMultiplier={1.5} />
                    </div>
                  :
                    <Button variant="contained" color="primary" onClick={() => onEnableClicked(user)}>Enable Subscription</Button>
                  }
                  
                </Box>
              }
            </Box>
            : <div></div>
          }
        </Container>
      }
    </AuthUserContext.Consumer>
  );
};

export default withAuthentication(Account);
