import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';
import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';


import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import { v4 as uuid } from 'uuid';

import ProjectContext from 'src/components/ProjectContext';

import {withAuthentication, AuthUserContext } from 'src/components/Session';



import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import { boolean } from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function AddNewItemDialog() {
  const [open, setOpen] = React.useState(false);
  const projectId = React.useContext(ProjectContext)

  const [skuValues, setSKUValues] = React.useState({
    sku: '',
  });

  const [skuErrorStateValue, setskuErrorStateValue] = React.useState({
    skuErrorState: false,
  })

  const [nameValues, setNameValues] = React.useState({
    name: '',
  });

  const [locationValues, setLocationValues] = React.useState({
    location: '',
  });
 

  const [quantityValues, setquantityValues] = React.useState({
    quantity: 0,
  });

  const [defaultQuantityValue, setdefaultQuantityValue] = React.useState({
    defaultQuantity: '',
  });


  const [categoryValues, setcategoryValues] = React.useState({
    category: '',
  });

  const [notesValues, setNotesValues] = React.useState({
    notes: '',
  });

  const [discontinuedValues, setDiscontinuedValues] = React.useState({
    discontinued: '',
  });

  const [unitPriceValues, setUnitPriceValues] = React.useState({
    price: 0,
  });

  const [defaultUnitPriceValue, setdefaultUnitPriceValue] = React.useState({
    defaultUnitPrice: '',
  });
  


  const handleClickOpen = () => {
    setOpen(true);

    //Clear Fields
    setdefaultQuantityValue({defaultQuantity: ''})
    setdefaultUnitPriceValue({defaultUnitPrice: ''})
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setSKUFunction = (data) => {
    console.log("sku: ", data)
    setSKUValues({sku: data})
  }

  const setNameFunction = (data) => {
    console.log("name: ", data)
    setNameValues({name: data})
  }

  const setLocationFunction = (data) => {
    console.log("location: ",data)
    setLocationValues({location: data})
  }

  const setQuantityFunction = (data) => {

    setdefaultQuantityValue({defaultQuantity: data})
    setquantityValues({quantity: Number(data)})    
  }

  const setCategoryFunction = (data) => {

    setcategoryValues({category: data})
  }
  

  const setNotesFunction = (data) => {

    setNotesValues({notes: data})
  }

  const setDiscontinuedFunction = (data) => {

    setDiscontinuedValues({discontinued: data})
  }

  


  const setUnitPriceFunction = (data) => {
    
    setdefaultUnitPriceValue({defaultUnitPrice: data})
    setUnitPriceValues({price: Number(data)})

  }

  const handleAddItem = () => {
    console.log("HandleAddItem")

    console.log("projectId: ", projectId)

    if(skuValues.sku == ''){
      //Highlight sku text box to indicate required field
      setskuErrorStateValue({skuErrorState: true})
      return
    }


    var db = firebase.firestore();

    var newDocUID = uuid()

    //Need to implement the UID -geledui
    //The UID appears to be working. Just need to make sure the rest of the project
    //supports it.
    // db.collection(`customer/${projectId}/inventory_raw_goods`).doc(skuValues.sku).set({
    db.collection(`customer/${projectId}/inventory_raw_goods`).doc(newDocUID).set({
      // uid: skuValues.sku, 
      uid: newDocUID,
      sku: skuValues.sku,
      name: nameValues.name,
      location: locationValues.location,
      unitPrice: unitPriceValues.price,
      totalPrice: (unitPriceValues.price * quantityValues.quantity),
      quantity: quantityValues.quantity,
      category: categoryValues.category,
      notes: notesValues.notes,
      discontinued: discontinuedValues.discontinued,
      lowInventoryAlertValue: 10, 
      emailAlert: "example@business.com",
      priceLog: [
        {
          unitPrice: unitPriceValues.price,
          units: quantityValues.quantity,
          timeStamp: firebase.firestore.Timestamp.fromDate(new Date())
        }],
    })
    .then(function() {
        console.log("Document successfully written!");
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });


    setOpen(false);
    
  };
  
  const classes = useStyles();


  return (
    <div>
      
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Create New Item
      </Button>
      
      
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create New Item</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please fill out information on the new item you would like to create
            </DialogContentText>

            <Grid item xs={12} direction="row" spacing={3}>

            </Grid>
            
            <div className={classes.root}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="sku"
                      label="Item SKU"
                      type="sku"    
                      name="SKU"
                      error={skuErrorStateValue.skuErrorState}
                      onChange={(event) => {

                        setskuErrorStateValue({skuErrorState: false})

                        setSKUFunction(event.target.value)
                      } } 
                    />
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>
                    <TextField            
                      margin="dense"
                      id="name"
                      label="Item Name"
                      type="name"
                      fullWidth
                      onChange={(event) => setNameFunction(event.target.value)} 
                    />
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper className={classes.paper}>
                    <TextField            
                      margin="dense"
                      id="location"
                      label="Location"
                      type="location"
                      fullWidth
                      onChange={(event) => setLocationFunction(event.target.value)} 
                    />
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper className={classes.paper}>
                    <TextField            
                      margin="dense"
                      id="qty"
                      label="Quantity"
                      type="qty"
                      value={defaultQuantityValue.defaultQuantity}
                      onChange={(event) => {

                        let input = event.target.value;
                        if(!isNaN(+input))
                          setQuantityFunction(input)
                        else
                          return null
                      }} 
                    />
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper className={classes.paper}>
                    <TextField
                      margin="dense"
                      id="price"
                      label="Current Unit Price"
                      type="price"
                      value={defaultUnitPriceValue.defaultUnitPrice}
                      onChange={(event) => {

                        let input = event.target.value;
                        if(!isNaN(+input))
                        setUnitPriceFunction(input)
                        else
                          return null
                        

                      } } 
                    />
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>
                  <TextField            
                    margin="dense"
                    id="notes"
                    label="Notes"
                    type="notes"
                    onChange={(event) => setNotesFunction(event.target.value)} 
                  />
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>
                    <TextField            
                      margin="dense"
                      id="cat"
                      label="category"
                      type="cat"
                      onChange={(event) => setCategoryFunction(event.target.value)} 
                    />
                  </Paper>
                </Grid>
              </Grid>
            </div>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
              <Button onClick={handleAddItem} color="primary">
                Add
              </Button>
          </DialogActions>
          {/* </form> */}
          
        </Dialog>
        
    </div>
  );
}
