import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import { navigate } from '@reach/router';
 
const withAuthentication = Component => {


  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
 
      this.state = {
        user: null,
      };
    }
 
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged((authUser) => {
          if (authUser) {
            this.props.firebase.getUser(authUser.uid).then(user => {
              this.setState({ user: user})
            });
          }
          else {
            this.setState({ user: null });
            navigate('/login');
          }
        });
    }
 
    componentWillUnmount() {
      this.listener();
    }
 
    render() {
      return (
        <AuthUserContext.Provider value={this.state.user}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
 
  return withFirebase(WithAuthentication);
};
 
export default withAuthentication;
