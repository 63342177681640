import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from '@reach/router';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from 'src/components/Firebase';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  Box,
  TextField,
} from '@material-ui/core';
import ProjectContext from 'src/components/ProjectContext';
import {withAuthentication, AuthUserContext } from 'src/components/Session';
import * as firebase from "firebase/app";
import {loadStripe} from '@stripe/stripe-js';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});







const ProjectList = (props) => {

  const classes = useStyles();
  const [projects, setProjects] = useState([]);
  const [noProjectsAvailable, setNoProjectsAvailable] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const navigate = useNavigate();
  const [projectName, setProjectName] = React.useState('');
  // const projectId = useContext(ProjectContext);
  // const [projectId, setProjectId] = React.useState('');

  // let thingId = ''


  const handleInput = (e) => {
    setProjectName(e.target.value);
  };

  const createProject = (user) => {
      
    console.log("Create new project", projectName)
    // console.log("projectId: ", projectId)
  
  
    
    props.firebase.createNewProject(projectName).then( response =>{        
        console.log("response: ", response.id)
        // navigate(`/app/${response.id}/inventory`);

        // setProjectId(response.id)

        onCheckoutClicked(user, response.id)

    })
  }
  

  const goToPayScreen = (user) => {


    //onCheckoutClicked(user)

      navigate('/createProject')
  }

  
  const selectProject = (id, name) => {

    props.firebase.setProject(id, name);
    navigate(`/app/${id}/inventory`);
  };
  // const createProject = () => {
  //   navigate('/createProject')
  // }

  async function onCheckoutClicked (user, projectId) {


  

  

    console.log("projectId: ", projectId)

    console.log("checkout clicked", user)

  
    // console.log("user: ", val)
  
    var db = firebase.firestore();
  
    const docRef = await db
    .collection('customer')
    .doc(projectId)
    .collection('checkout_sessions')
    .add({
      price: 'price_1InxCHAssoeWWRHKVtKVm2X4',
      allow_promotion_codes: true,
      success_url: window.location.origin + `/createProject`, //`/app/${projectId}/inventory`,
      cancel_url: window.location.origin,
    });
  


    // [...]
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data();

      console.log("snap.data(): ",snap.data())

      if (error) {
        // Show an error to your customer and 
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe

        console.log("Init Stripe: ",sessionId)

        // const stripe = await loadStripe('pk_test_51IgKivAssoeWWRHK8dKHlPKLhCmYIW3kM1hfffFsyFlMoaAf6mlWSiT9nmJKOpzPPYMGhQAFWNNWN3XlkNrUsSR400UTxhjraI');
        const stripe = await loadStripe('pk_live_51IgKivAssoeWWRHKmk76eg6XchLKHTOfEw5FaNxMMTsr1Z8LIxvKVcKLPiYfF98OQfyIQF50gJKjSBScGUfZ0mSF00e4YWlFp3');


        console.log("Init Stripe: ",stripe)
        


        stripe.redirectToCheckout({ sessionId }).then(function(result) {

          console.log("Checkout success: ", result)

          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.

          if (result.error) {

            console.log("Checkout failed: ")
            alert(result.error.message);
          }
        });
      }
    });
  
  }


  useEffect(() => {
    console.log(props.user);
    if (props.user) {
      setIsLoading(true)
      props.firebase.getProjects(props.user.id)
        .then(projects => {
          setProjects(projects);
          setIsLoading(false)
        })
        .catch(error => {
          console.log(error);
          setIsLoading(false)
          setNoProjectsAvailable(true)
        });
    }
  }, [props.user]);

  return (


    <Container maxWidth="sm">
       <Typography variant="h1" component="h2" gutterBottom align='center'>
        Projects
      </Typography>

      {isLoading == true ?
       <Typography variant="h3" component="h3" gutterBottom align='center'>
          loading...
       </Typography>
       : 
       <Grid container>
         {(noProjectsAvailable == true ) ? 
          <Grid item xs={12}>
            <Box >
              <Card>
                <CardContent align='center'>
                  <Box paddingTop={10}  textAlign='center'>
                    <Typography variant="h2" component="h2"  paddingY={20}>
                      Let's get you set up!
                    </Typography>
                  </Box>
                  <Box paddingTop={10}  textAlign='center'>
                    <Typography variant="h3" component="h3"  paddingY={20}>
                    No Charge For The First 14 Days
                    </Typography>
                  </Box>
                  <Box paddingTop={5}  textAlign='center'>
                    <Typography variant="h4" component="h3"  paddingY={20}>
                    Cancel At Anytime
                    </Typography>
                    
                  </Box>
                  <Box>
                  {/* <Box mt={20} align='center'>
                          <Typography variant="h3" component="h3" gutterBottom align='center'>
                              New Project
                          </Typography>
                          <TextField 
                              onChange={handleInput}
                              id="standard-basic" label="Project Name"  align='center'/>
                      </Box> */}
                  </Box>
                  <AuthUserContext.Consumer>
                  {user => 
                    // <Box paddingTop={10} textAlign='center'>
                    //   <Button variant="contained" color="primary" onClick={() => createProject(user)}>Create new project</Button>
                    // </Box>
                    <Box paddingTop={5} textAlign='center'>
                      <Button variant="contained" color="primary" onClick={() => goToPayScreen(user)}>Get started</Button>
                    </Box>
                  }
                </AuthUserContext.Consumer>
                <Box align='center' padding={2}>
                  <Typography variant="h4" component="h3"  paddingY={20}>
                    Card Info Only Held And Processed With Stripe
                  </Typography>
                  <img src={"checkmark4.png"} alt="Logo" style={{width: 50, height: 50}} />
                </Box>
                </CardContent>
                
              </Card>
            </Box>
          </Grid>
          
         :
          <Grid item xs={12}>
              {projects.map((project) => (
                <Card
                  onClick={(e) => selectProject(project.id, project.name, e)}
                  key={project.id}
                  className={classes.root}
                  boxShadow={0}
                  style={{ 
                    backgroundColor: '#e3eaef', 
                    margin: 10}}
                  >
                <CardContent align='center'>
                  {project.name}
                </CardContent>
              </Card>
              ))}
            </Grid>}
        </Grid>
       }
      
    </Container>
  );
};

export default withFirebase(withAuthentication(ProjectList));
