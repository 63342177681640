import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import MyDocument from './MyDocument'
import ProjectContext from 'src/components/ProjectContext';

export default function MaterialUIPickers() {
  // The first commit of Material-UI
  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date()); //'2020-08-20'
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());     //'2020-10-07'

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    console.log("date: ",selectedStartDate.getTime()/1000)
  };
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  return (
    <ProjectContext.Consumer>
      {projectId =>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Orders Starting From"
              value={selectedStartDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              margin="normal"
              id="date-picker-dialog"
              label="Orders Ending On"
              format="MM/dd/yyyy"
              value={selectedEndDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            {/* <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Time picker"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            /> */}
          </Grid>

          <PDFViewer 
            width={440}
            >
              <MyDocument StartDate={selectedStartDate} EndDate={selectedEndDate} projectId={projectId}/>
          </PDFViewer>
          <Box p={0.4}></Box>
          <Box p={0.5}>
            <PDFDownloadLink
             document={<MyDocument StartDate={selectedStartDate} 
             EndDate={selectedEndDate} 
             projectId={projectId}/>} 
             fileName={selectedStartDate.toDateString()+ `-` + selectedEndDate.toDateString() + ` Inventory Simplified Sales Report`+  `.pdf`}
             >
                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download Sales Report')}
            </PDFDownloadLink>
          </Box>
        </MuiPickersUtilsProvider>
      }
    </ProjectContext.Consumer>
  );
}
