import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  makeStyles
} from '@material-ui/core';
import AddNewItemDialog from './dialogs/AddNewItemDialog';
import ImportDialog from './dialogs/ImportDialog'
import SalesOptionsDialog from './dialogs/SalesOptionsDialog'
import TotalSales from '../../reports/DashboardView/TotalSales';
import LowInventoryCard from 'src/views/reports/DashboardView/LowInventoryCard';
import InventorySnapShotCard from '../../reports/DashboardView/InventorySnapShotCard';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}>
      <Box
        display="flex"
        justifyContent="flex-end"
        marginRight={20}>

        <Box
          marginRight={4.3}
          marginBottom={2}>
          <Box marginBottom={4.35}>
            {/* <TotalProfit/> */}
            <InventorySnapShotCard/>
          </Box>
          <Box>
            <LowInventoryCard/>
          </Box>
        </Box>
        <Box>
          <TotalSales/>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
          {/* <Button>Sales Destination Options</Button> */}
          <SalesOptionsDialog/>
      </Box>
      <Box paddingY={2}>
        <AddNewItemDialog></AddNewItemDialog>
      </Box>
      <Box>
        <ImportDialog></ImportDialog>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
