import React from "react";
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
var QRCode = require('qrcode.react');

const AverySticker = (props) => {

  return (
    <div style={{ width: "4.01in", padding: "1rem" }}>
      <Grid container>
        <Grid item spacing={2}>
          <QRCode value={props.sku}/>
        </Grid>
        <Grid item spacing={2}>
          <Container>
            <Typography color="textSecondary" margin={5}>
              {props.showSku ? props.sku : ""}
            </Typography>
            <Typography color="textSecondary" margin={5}>
              {props.showLocation ? props.location : ""}
            </Typography>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
};

export default AverySticker
