import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ProjectContext from 'src/components/ProjectContext';
import EditItemFormDialog from './dialogs/EditItemFormDialog';
import EditItemNotesDialog from './dialogs/EditItemNotesDialog';
import EditItemPrintSettingsDialog from './dialogs/EditItemPrintSettingsDialog';
import AuditQuantityDialog from './dialogs/AuditQuantityDialog';
import {
  Button,
  Card,
  makeStyles,
  TextField,
  Text,
} from '@material-ui/core';
import MaterialTable from 'material-table';

import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { CSVLink, CSVDownload } from "react-csv";
import { Link as RouterLink, useNavigate, useLocation } from '@reach/router';




import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PrintQRCode from './dialogs/PrintQRCode'




import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import { TramOutlined } from '@material-ui/icons';


var QRCode = require('qrcode.react');
var omit = require('object.omit');

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));


const Results = ({ className, customers, ...rest }) => {
  const classes = useStyles();
  const projectId = useContext(ProjectContext);
  const navigate = useNavigate()
  const location = useLocation()
  
  const [state, setState] = React.useState({
    columns: [
      { title: 'SKU', field: 'sku' },
      { title: 'Item Name', field: 'name' },
      { title: 'Cost of Goods ($)', field: 'totalPrice', editable: 'never'  },
      { title: 'Location', field: 'location',
        // lookup: { 34: 'Row 3 Shelf 2', 63: 'Row 5 Shelf 8' },
      },
      { title: 'Category', field: 'category' },
      { title: 'Discontinued', 
        field: 'discontinued',
        lookup: { true: 'true', false: 'false' },
      },
      { title: 'Quantity', field: 'quantity', editable: 'never' },
      
    ],
    data: [
      { sku: 'FP9180', name: 'Front Porch Special', quantity: 87, location: 63 },
      {
        sku: 'BR0821',
        name: 'Bergomot',
        location: 34,
        category: 'COGS',
        discontinued: true,
        quantity: 17,
      },
    ],
  });


  const [showPrintQRCodeDialog, setShowPrintQRCodeDialog] = React.useState(false)
  const [printData, setPrintData] = React.useState([])
  const [openPrintDialog, setOpenPrintDialog] = React.useState(false)

  const handleClosePrintDialog = () => {
    setOpenPrintDialog(false)
  }


  const [showDeleteSelectedDialog, setShowDeleteSelectedDialog] = React.useState(false)
  const [deleteData, setDeleteData] = React.useState([])
  const [openDeleteSelectedDialog, setOpenDeleteSelectedDialog] = React.useState(false)
  
  const handleCloseDeleteSelectedDialog = () => {
    setOpenDeleteSelectedDialog(false)
  }

  const deleteSelectedItems = () => {
    console.log("Test Function: ", deleteData)


    var db = firebase.firestore();

    // Get a new write batch
    var batch = db.batch();
    


    deleteData.map((item, index) => {
      
      console.log("item.uid: ",item.uid)

      // Delete item
      var ref = db.collection(`customer/${projectId}/inventory_raw_goods`).doc(item.uid);
      batch.delete(ref);
    }) 
    
    

    // Commit the batch
    batch.commit().then(function () {
      // ...
    });

    setOpenDeleteSelectedDialog(false)
  }


  

  const [showDownloadDialog, setShowDownloadDialog] = React.useState(false)
  const [downloadData, setDownloadData] = React.useState([])
  const [openDownloadDialog, setOpenDownloadDialog] = React.useState(false)


  const [csvDataWithRequiredFields, setCsvDataWithRequiredFields] = React.useState({})


  const handleCloseDownloadDialog = () => {
    setOpenDownloadDialog(false)
  }


  const gotoSKUDetails = (uid) => {

    navigate(`${uid}`)
  }


  // const csvHeader = [
  //   ["SKU", "name", "quantity", "location", "unit price $ (optional)", "Category", "Notes", "Discontinued"],
  //   ["TESTSKU1", "TestName1", "100", "Shelf 2, Row 1", "2.1", "COGS", "Example Note", "true"],
  // ];


  const csvHeader = [
    { SKU: "TESTSKU1", name: "TestName1", quantity: "100", location: "Shelf 2, Row 1", 
    unitPrice: "2.1", category: "COGS", notes: "example note", discontinued: "true" },
  ];
  const handleDownloadSelectedItems = () => {

    console.log("csvHead: ", csvHeader)
    console.log("downloadData: ", downloadData)



    var result = []

    downloadData.map((item, index) => {

      var itemWithOmittingFields = omit(item, ['priceLog', 'tableData', 'totalPrice'] )

      
      
      result.push(itemWithOmittingFields)

      
      
    })


    console.log("result: ", result)

    setCsvDataWithRequiredFields(result)

  
  }







  return (
    
    <div>

    

    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >



    <Dialog open={openPrintDialog} onClose={handleClosePrintDialog} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth = {'md'}>
      <DialogTitle id="form-dialog-title">QR Codes and Labels to print</DialogTitle>
      <DialogTitle id="form-dialog-title">These should be printed on a Avery Template 5163 2" x 4"</DialogTitle>
        <DialogContent>
          <PrintQRCode customers={printData}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePrintDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
    </Dialog>



    <Dialog open={openDeleteSelectedDialog} onClose={handleCloseDeleteSelectedDialog} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Are you sure you want to delete the selected data?</DialogTitle>
      <DialogTitle id="form-dialog-title">Waring! This action cannot be undone.</DialogTitle>
        
        <DialogActions>
          <Button onClick={deleteSelectedItems} color="primary" variant="contained">
            Yes
          </Button>
          <Button onClick={handleCloseDeleteSelectedDialog} variant="contained">
            No
          </Button>
        </DialogActions>
        
        
        <DialogActions>
          <Button onClick={handleCloseDeleteSelectedDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
    </Dialog>


    <Dialog open={openDownloadDialog} onClose={handleCloseDownloadDialog} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Items will be downloaded as a CSV</DialogTitle>
        
        <DialogActions>
          <Button onClick={handleDownloadSelectedItems} color="primary" variant="contained">
            Set Data
          </Button>
        </DialogActions>
        <CSVLink data={csvDataWithRequiredFields}>DownloadData</CSVLink>
        
        <DialogActions>
          <Button onClick={handleCloseDownloadDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
    </Dialog>



<MaterialTable
      title="Inventory"
      columns={state.columns}
      data={customers} //{state.data}
      options={{
        pageSize: 20,
        pageSizeOptions: [5, 10, 20, 50, 75, 100 ],
        selection: true
      }}
      actions={[
        {
          tooltip: 'Print selected QR Codes',
          icon: 'print',
          onClick: (evt, data) =>  {
            setShowPrintQRCodeDialog(true)
            setOpenPrintDialog(true)
            setPrintData(data)
          }
        },
        {
          tooltip: 'Dowload select items as CSV',
          icon: 'download',
          onClick: (evt, data) =>  {
            setShowDownloadDialog(true)
            setOpenDownloadDialog(true)
            setDownloadData(data)
            handleDownloadSelectedItems()
          }
        },
        {
          tooltip: 'Delete selected items',
          icon: 'delete',
          onClick: (evt, data) => {
            setShowDeleteSelectedDialog(true)
            setOpenDeleteSelectedDialog(true)
            setDeleteData(data)
          }
        },
      ]}
      detailPanel={[
        {
          tooltip: 'Show Details',
          render: rowData => {
            return (
              <div
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  // display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                }}
                  >
                    <div style={{
                          paddingBlock: 10,}}></div>
                    <Typography gutterBottom variant="h5" component="h2">
                      Item Details
                    </Typography>
                    <Box
                        paddingTop={2}
                        paddingBottom={5}
                        display="flex"
                        // display: "flex",
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="row">
                          <Box  paddingX={5}>
                            <Card className={classes.root} paddingX={10}>
                              <CardContent align='center'>
                                  <Typography className={classes.pos} color="textSecondary">
                                    SKU QR Code
                                  </Typography>
                                  <QRCode value={rowData.sku} />
                                  <Typography className={classes.pos} color="primary">
                                    {rowData.sku}
                                  </Typography>
                              </CardContent>
                              <Box paddingTop={1} textAlign='center'>
                                <EditItemPrintSettingsDialog value={rowData}></EditItemPrintSettingsDialog>
                              </Box>
                            </Card>
                          </Box>
                          <Card className={classes.root} paddingX={5}>
                            <CardContent >
                              <Typography gutterBottom variant="h5" component="h2" align='center'>
                                Low Stock Alert
                              </Typography>
                              <Typography style={{color: "#797979"}} align='center'>
                                Send Alert To
                              </Typography>
                              <Box style={{backgroundColor: '#eaedf0', borderRadius: 5}}>
                                <Typography className={classes.pos} color="textPrimary" width={300} align='center'>
                                  {rowData.emailAlert}
                                </Typography>
                              </Box>
                              
                              <Typography style={{color: "#797979"}} align='center'>
                                 At Minimum
                              </Typography>
                              <Box style={{backgroundColor: '#eaedf0', borderRadius: 5}}>
                                <Typography className={classes.pos} color="textPrimary" align='center'>
                                {rowData.lowInventoryAlertValue} Units Left
                                </Typography>
                              </Box>
                              <Box paddingTop={7} textAlign='center'>
                                <EditItemFormDialog value={rowData.uid}></EditItemFormDialog>
                              </Box>
                            </CardContent>
                          </Card>
                          <Box  paddingX={5}  maxWidth={205}>
                            <Card className={classes.root} paddingX={10} minHeight={180} maxHeight={180}>
                              <CardContent>
                              <Typography className={classes.pos} color="textSecondary">
                                {rowData.notes}
                              </Typography>
                              <Box paddingTop={1}  textAlign='center'>
                                <EditItemNotesDialog value={rowData.uid}></EditItemNotesDialog>
                              </Box>
                              </CardContent>
                            </Card>
                          </Box>
                          <Box>
                            <Box paddingX={5}>
                              <Button variant="contained" color="primary" onClick={() => gotoSKUDetails(rowData.uid)}>Transaction History</Button>
                            </Box>
                            <Box paddingTop={1} textAlign='center' >
                              <AuditQuantityDialog value={rowData} style={{width: 100}}></AuditQuantityDialog>
                            </Box>
                          </Box>
                          
                    </Box>

                  {/* <Box margin={1}>
                    <Typography variant="h6" gutterBottom component="div">
                    Mobile App Receive History
                    </Typography>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell align="center">Units Added</TableCell>
                          <TableCell align="center">Unit Price ($)</TableCell>
                          <TableCell align="center">Units Price Difference ($)</TableCell>
                          <TableCell align="center">Unit Quantity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowData.priceLog.map((priceLog) => (
                          <TableRow key={priceLog.timeStamp.toDate().toDateString()}>
                            <TableCell component="th" scope="row">
                              {priceLog.timeStamp.toDate().toDateString()}
                            </TableCell>
                            <TableCell align="center">{priceLog.unitsAdded}</TableCell>
                            <TableCell align="center">{priceLog.unitPrice}</TableCell>
                            <TableCell align="center">{Math.round(priceLog.unitPriceDifference * 100)/100}</TableCell>
                            <TableCell align="center">{priceLog.units}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box> */}
                
              </div>
            )
          },
        },        
      ]}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              if (oldData) {
                if(newData === oldData) 
                //This needs to diff all elements. 
                //Can't just compare the objecst because timestamp will always be different
                // Look into saving money here!!!!!!
                //!!!!!!!!!! Need to figure out a better way to do this so that it won't run up api calls !!!!!
                {
                  return
                }
                
                setState((prevState) => {
                  const data = [...prevState.data];
                  data[data.indexOf(oldData)] = newData;

                  var db = firebase.firestore();

                  var unitsRemoved = newData.quantity - oldData.quantity


                  console.log("unitsRemoved: ",unitsRemoved)


                  if(unitsRemoved < 0)
                  {
                    //Items are being removed from inventory
                    // Need to Subtract cost of goods from oldest price log date.

                    console.log("Units removed!!!!!")

                    db.collection(`customer/${projectId}/inventory_raw_goods`).doc(newData.uid).get().then(function(doc) {
                      if (doc.exists) {

                          var jsonData = doc.data();
                          var sumOfUnits = 0
                          var sumTotalPrice = 0
                          var remainingUnitsToSubtract = -unitsRemoved 

                          for(let i = 0; i<jsonData.priceLog.length && remainingUnitsToSubtract !== 0; i++)
                          {
                            
                            if(remainingUnitsToSubtract > 0){
                              
                              var diff = jsonData.priceLog[i].units - remainingUnitsToSubtract

                              if(diff < 0 )
                              //If there are more than current units to subtract then do the following
                              {
                                remainingUnitsToSubtract = remainingUnitsToSubtract - jsonData.priceLog[i].units
                                jsonData.priceLog.shift()
                                i--  
                              }
                              else
                              //If the current unit has enough for subtraction then do the following
                              {
                                jsonData.priceLog[i].units = jsonData.priceLog[i].units - remainingUnitsToSubtract
                                remainingUnitsToSubtract = 0
                              }
                            }
                          }

                            //Recalculate the total quanitty 
                            for(let i = 0; i < jsonData.priceLog.length; i++ )
                            {
                              console.log("SHIFT Just happened OLD JSON DATA: ",i," :",jsonData.priceLog[i])
                              sumOfUnits += jsonData.priceLog[i].units
                              sumTotalPrice += jsonData.priceLog[i].unitPrice * jsonData.priceLog[i].units
                            }


                            //Update jsonData with ALL updated newData
                            jsonData.sku = newData.sku
                            jsonData.name = newData.name
                            jsonData.quantity = sumOfUnits
                            jsonData.totalPrice = (Math.round(sumTotalPrice * 100)/100) //Round to the nearest .00
                            jsonData.category = newData.category
                            jsonData.discontinued = newData.discontinued


                            db.collection(`customer/${projectId}/inventory_raw_goods`).doc(newData.uid).update(jsonData)
                            .then(function() {
                                console.log("Document successfully written!");
                            })
                            .catch(function(error) {
                                console.error("Error writing document: ", error);
                            });
                          

                      } else {
                          // doc.data() will be undefined in this case
                          console.log("No such document!");
                      }
                    }).catch(function(error) {
                        console.log("Error getting document:", error);
                    });
                  }
                  else
                  //Items are being added to inventory
                  // Recalculate the totalPrice.
                  {
                    db.collection(`customer/${projectId}/inventory_raw_goods`).doc(newData.uid).get().then(function(doc) {
                      if (doc.exists) {

                          var jsonData = doc.data();
                          var sumTotalPrice = 0

                          //Recalculate the total quanity and total price from current data in the db.

                          //Get Unit Price Total for oldData
                          sumTotalPrice = oldData.quantity * oldData.unitPrice

                          //Add the new quantity * current unit price to the sumTotalPrice
                          sumTotalPrice += ((newData.quantity - oldData.quantity) * newData.unitPrice)


                          //Need to add the new data values to the current data that lives in the db.
                          //Update jsonData with ALL updated newData
                          jsonData.sku = newData.sku
                          jsonData.name = newData.name
                          jsonData.quantity = newData.quantity 
                          jsonData.totalPrice = (Math.round(sumTotalPrice * 100)/100) //Round to the nearest .00
                          jsonData.category = newData.category
                          jsonData.discontinued = newData.discontinued


                          db.collection(`customer/${projectId}/inventory_raw_goods`).doc(newData.uid).update(jsonData)
                          .then(function() {
                              console.log("Document successfully written!");
                          })
                          .catch(function(error) {
                              console.error("Error writing document: ", error);
                          });
                          

                      } else {
                          // doc.data() will be undefined in this case
                          console.log("No such document!");
                      }
                      }).catch(function(error) {
                          console.log("Error getting document:", error);
                      });
                  }
                  return { ...prevState, data };
                });
              }
            }, 600);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            console.log("try to delete:")
            setTimeout(() => {
              resolve();
              setState((prevState) => {
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                var db = firebase.firestore();

                db.collection(`customer/${projectId}/inventory_raw_goods`).doc(oldData.uid).delete().then(function() {
                    console.log("Document successfully deleted!");
                }).catch(function(error) {
                    console.error("Error removing document: ", error);
                });

                return { ...prevState, data };
              });
            }, 600);
          }),
      }}
    />
    </Card>

    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
