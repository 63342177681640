import React from 'react';

const Logo = (props) => {
  return (
    // <img
    //   alt="Logo"
    //   src="/static/logo.svg"
    //   {...props}
    // />
    <img src={require('./Icon.png')} />
  );
};

export default Logo;
