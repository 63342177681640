import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TimelineIcon from '@material-ui/icons/Timeline';
import {
    Avatar,
    Grid,
  } from '@material-ui/core';
import MaterialUIPickers from './MaterialUIPickers'
import { withFirebase } from 'src/components/Firebase';
import ProjectContext from 'src/components/ProjectContext';

/*const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    backgroundColor: 'green', //colors.green[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: 'green'//colors.green[900]
  },
  differenceValue: {
    color: 'green',
    marginRight: 10 //theme.spacing(1)
  }
});*/

class TotalSales extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      totalPurchasePrice: 0,
      totalItemsRemoved: 0,
      expanded: false,
    };
    this.handleExpandClick = this.handleExpandClick.bind(this)
  }

  handleExpandClick() {
    this.setState({expanded: !this.state.expanded});
  }

  componentDidMount() {

    var db = this.props.firebase.firestore;
    var projectId = this.context;
    var refs = db.doc(`customer/${projectId}/salesReport/online`);
    var callback = this.promise;

    refs.get(callback)

    var promise = new Promise( (resolve, reject) => {


      db.doc(`customer/${projectId}/salesReport/online`).get()
        .then(function(doc) {
          if (doc.exists) {
            resolve(doc.data());
          }
        })
        .catch(function(error) {
          reject(`Error retrieving online sales report for project id ${projectId}\n${error}`)
        });
    });
    promise.then( result => {
      this.setState({totalPurchasePrice: Math.round(-result.totalPurchasePrice * 100)/100});
      this.setState({totalItemsRemoved: -result.totalItemsRemoved});
    }, function(error) {
      throw error;
    });
  }

  render() {
    return (
      <Card >
        <CardContent>
          <Grid
          container
          justify="space-between"
          spacing={3}>
            <Grid item>
              <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
                SALES REPORT
            </Typography>
            <Typography
            color="textPrimary"
            variant="h3"
          >
            </Typography>
            <MaterialUIPickers/>
          </Grid>
          <Grid item>
            <Avatar >
              <TimelineIcon />
            </Avatar>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
    )
  }
}
TotalSales.contextType = ProjectContext;

export default withFirebase(TotalSales);
