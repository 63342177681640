import React from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import SignUpForm from 'src/components/SignUpForm';
import { useCookies } from 'react-cookie';
import { useLocation, navigate } from '@reach/router';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = (props) => {
  const classes = useStyles();
  const [cookies, setCookies] = useCookies(['joinData']);
  const search = new URLSearchParams(useLocation().search);
  const joinData = search.get('joinData')

  if (joinData) {
    setCookies('joinData', decodeURIComponent(joinData));
    navigate('/login');
  }

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <SignUpForm joinData={props.joinData}/>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
