import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    containerMain: {
        // flexDirection: 'row',
        borderBottomColor: 'black',
        backgroundColor: 'white',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 64,
        textAlign: 'right',
        fontStyle: 'bold',
        flexGrow: 1,
        fontSize: 15,
    },
    containerRow: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        backgroundColor: 'lightgray',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
        fontSize: 15,
    },
    destinationName: {
        width: '100%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    sku: {
        width: '35%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    description: {
        width: '20%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    transactionType: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    subTotal: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });

  const InvoiceTableHeader = ({destinationName}) => {

    return (
        <View style={styles.containerMain}>     
        <Text style={styles.destinationName}> Destination: {destinationName}</Text>
            <View style={styles.containerRow}>
                
                <Text style={styles.sku}>Item Sku</Text>
                <Text style={styles.description}>Name</Text>
                <Text style={styles.qty}>Qty</Text>
                <Text style={styles.transactionType}>Trans. Type</Text>
                <Text style={styles.subTotal}>Subtotal</Text>
            </View>       
            
        
        </View>
    )

  };
  
  export default InvoiceTableHeader
