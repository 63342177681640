import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProjectContext from 'src/components/ProjectContext';
import AverySticker from 'src/views/customer/CustomerListView/AverySticker.js';
import { withFirebase } from 'src/components/Firebase';

const EditItemPrintSettingsDialog = (props) => {
    

  var QRCode = require('qrcode.react');
  const [open, setOpen] = React.useState(false);
  const projectId = React.useContext(ProjectContext)

  const [nameChecked, setnameChecked] = React.useState(true);

  const handleNameBoxChange = (event) => {
    setnameChecked(event.target.checked);   
  }

  const [locationChecked, setlocationChecked] = React.useState(true);

  const handleLocationBoxChange = (event) => {
    setlocationChecked(event.target.checked);
  };

  const handleClickOpen = () => {

    props.firebase.firestore.collection(`customer/${projectId}/inventory_raw_goods`).doc(props.value.uid).get()
    .then(function(doc) {

        if (doc.exists) {
            var jsonData = doc.data();

            console.log("set values")
            // setLowInventoryAlertFunction(Number(jsonData.lowInventoryAlertValue))
            // setEmailAddressFunction(jsonData.emailAlert)
            console.log("after set values")
        }
        

        console.log("Document successfully read!");
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });


    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleModifyProperty = (formData) => {
    const docRef = props.firebase.firestore.collection(`customer/${projectId}/inventory_raw_goods`).doc(props.value.uid);
    props.firebase.firestore.runTransaction(transaction => {

      return transaction.get(docRef).then(docSnapshot => {
        if (!docSnapshot.exists) {
          throw `Document snapshot for ${props.value.uid} doesn't exist.`;
        }

        const stickerPrintSettings = {
          showName: nameChecked,
          showLocation: locationChecked
        };

        return transaction.update(docRef, { stickerPrintSettings: stickerPrintSettings });

      });
    })
    .then(newData => {
      console.log(newData);
      handleClose();
    })
    .catch(err => {
      console.error(err);
      handleClose();
    });
  };
  


let locationText;
 if (locationChecked) {
    locationText = <Typography color="textSecondary" margin={5}>
                {props.value.location}
            </Typography>
  } else {
    locationText = <Typography color="textSecondary" margin={5}>
                
            </Typography>
  }

let nameText;
 if (nameChecked) {
    nameText = <Typography color="textSecondary" margin={5}>
                {props.value.name}
            </Typography>
  } else {
    nameText = <Typography color="textSecondary" margin={5}>
                
            </Typography>
  }
  

  return (
    <div>
      
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Edit Print Settings
      </Button>
      
      
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">SKU Label Print Settings</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Edit how SKU label and details will appear on avery sticker
            </DialogContentText>
            <Box 
                paddingTop={5}
                display="flex"
                alignItems='center'
                flexDirection="row">
              <AverySticker sku={nameText} showSku={nameChecked} location={locationText} showLocation={locationChecked}/>
            </Box>

            <FormGroup row>
                <FormControlLabel
                        control={<Checkbox
                            checked={nameChecked}
                            onChange={handleNameBoxChange}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />}
                        label="Item Name"
                    />
                <FormControlLabel
                    control={<Checkbox
                        checked={locationChecked}
                        onChange={handleLocationBoxChange}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />}
                    label="Location"
                />
            </FormGroup>

            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleModifyProperty} color="primary">
              Save
            </Button>
          </DialogActions>
          {/* </form> */}
          
        </Dialog>
        
    </div>
  );
}

export default withFirebase(EditItemPrintSettingsDialog);
