import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import TransactionTable from './TransactionTable'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    paddingTop: 30,
    paddingLeft:0,
    paddingRight:60,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    //flexDirection: 'row'
  },
  lineEntry: {
    flexDirection: 'row'
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Times-Roman'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Times-Roman'
  },
  text: {
    //margin: 3,
    paddingRight: 81,
    fontSize: 11,
    //textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  LineEntrytext1: {
    paddingRight: 100,
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: 11,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
    backgroundColor: 'gray'
  },
  LineEntrytext2: {
    paddingRight: 100,
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: 11,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
    backgroundColor: 'lightgray'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: 'black',
  },
});
// Create Document Component

class MyDocument extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      saleDestinationName: "",
      totalPurchasePrice: 0,
      totalItemsRemoved: 0,
      destinationNames: [],
      transactionsData: [],
      collectionsDocDataState: [],
      expanded: false,
    };
    this.transactionTables = [];
  }

  componentDidMount() {

    var db = firebase.firestore();
    var projectId = this.props.projectId;
    var refs = db.doc(`customer/${projectId}/salesReport/online`);
    var callback = this.promise;

    refs.get(callback)

    var promise = new Promise( (resolve, reject) => {
    

      db.collection(`customer/${projectId}/salesReport`).get()
      .then(function(snapshot)
      {        
        resolve(snapshot)

      }).catch(function(error) {
            console.error("Error writing document: ", error);
        reject(error)
      });
    });
    
    promise.then( result => {        


        var collectionsDocData = result.docs.map(doc => {
          return {
            name: doc.id,
            data: doc.data()
          }
        });

        this.setState({collectionsDocDataState: collectionsDocData})

        this.quantitySum = 0
        this.subTotalSum = 0


        var MinusHours=0
        var PlusHours=0

        if((this.props.StartDate.toDateString()) == (this.props.EndDate.toDateString()))
        {
          MinusHours=28800000
          PlusHours=28800000
        }
        
        this.transactionTables = [];
        if(this.state.collectionsDocDataState)
        {
          this.transactionTables = this.state.collectionsDocDataState.map(collection => 
            {
              var transactionItemsFilteredOnDateRange = []

              if (collection.data.transactions) {
                for(var i = 0; i < collection.data.transactions.length; i++)
                {
                  if(((this.props.StartDate.getTime() - MinusHours) / 1000) < collection.data.transactions[i].timeStamp.seconds 
                  &&  collection.data.transactions[i].timeStamp.seconds < ((this.props.EndDate.getTime() + PlusHours) / 1000))
                  {
                    transactionItemsFilteredOnDateRange.push(collection.data.transactions[i])
                    this.quantitySum += collection.data.transactions[i].quantity
                    this.subTotalSum += collection.data.transactions[i].subTotal
                  }
                }
              }
              return <TransactionTable key={collection.name} destinationName={collection.name} transactionItems={transactionItemsFilteredOnDateRange}></TransactionTable>
            })
        }

    }, function(error) {
        console.log("Error")
    });
  }

  updateCollectionsDocData(querySnapshot) {
  }

  render() {
    return(
      <Document>
        <Page size="A4" style={styles.page} >                
          <View style={styles.section}>
          
            <Text style={styles.title}>Sales Report</Text>

            <Text>{this.props.StartDate.toDateString()} - {this.props.EndDate.toDateString()}</Text>
            
            <Text>Sales Total: $ {Math.round(this.subTotalSum * 100)/100}</Text>
            <Text>Total Items Sold: {-this.quantitySum}</Text>

            <View style={styles.tableContainer}>
                {this.transactionTables}
            </View>
          </View>
          
        </Page>
      </Document>
    )
  }
}
export default MyDocument;
