import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import {
    Avatar,
    Box,
    Grid,
  } from '@material-ui/core';
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import ProjectContext from 'src/components/ProjectContext';


class InventorySnapShotCard extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    componentDidMount() {

        var db = firebase.firestore();
        const projectId = this.context;
    
        var refs = db.doc(`customer/${projectId}/inventory_raw_goods/CRCEB`);
      
        var callback = this.promise;
    
        refs.get(callback)
    
        var promise = new Promise( (resolve) => {
        

            db.collection(`customer/${projectId}/inventory_raw_goods`).get()
          .then(function(snapshot)
          {        
    
            resolve(snapshot)
    
          }).catch(function(error) {
                console.error("Error writing document: ", error);
          });
        });
        
        promise.then( result => {        
            var collectionsDocData = result.docs.map(doc => {
            
              return {
                name: doc.id,
                data: doc.data()
              }
            });    

            this.setState({collectionsDocDataState: collectionsDocData})
    
    
        }, function(error) {
            console.log("Error")
        });
    }
    

    render() {

        var totalStock = 0
        var totalInventoryPrice = 0

        if(this.state.collectionsDocDataState)
        {
          this.state.collectionsDocDataState.map(collection => 
            {
                totalStock += collection.data.quantity
                totalInventoryPrice += collection.data.totalPrice
            })
        }
      
        return (
            <Card >
              <CardContent>
                <Grid
                container
                justify="space-between"
                spacing={3}
                >
                <Grid item>
                    <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                    >
                    INVENTORY SNAPSHOT
                    </Typography>
                    <Typography
                    color="textPrimary"
                    variant="h3"
                    >
                        {/* ${this.state.totalInventoryDollarValue} */}
                        Total Stock: {totalStock} Items
                    </Typography>
                </Grid>
                <Grid item>
                    <Avatar >
                      <SentimentSatisfiedAltIcon/>
                    </Avatar>
                </Grid>
                </Grid>
                <Box
                mt={2}
                display="flex"
                alignItems="center"
                >
                <Typography   
                    variant="body2">
                    Total Stock Value: $ 
                    {totalInventoryPrice.toLocaleString(undefined, {maximumFractionDigits:2})}
                </Typography>
                </Box>
                </CardContent>
            </Card>
          )
    }
}
InventorySnapShotCard.contextType = ProjectContext;

export default InventorySnapShotCard;
