import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withFirebase } from 'src/components/Firebase';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const RoleSelector = (props) => {

  const classes = useStyles();
  const handleChange = (event) => {
    console.log(event);
    console.log(event.target);
    //props.firebase.setRole(props.user.id, event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">Role</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={props.user.role}
        onChange={handleChange}
      >
        {props.firebase.userRoles.map((role) => (
          <MenuItem key={role.dbProperty} name={role.dbProperty} value={role.name}>{role.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default withFirebase(RoleSelector);
