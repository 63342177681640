
import React, { useContext }  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  makeStyles
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ProjectContext from 'src/components/ProjectContext';
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import MaterialTable from "material-table";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));


// const projectId = useContext(ProjectContext);
//   const [testData, setTestData] = React.useState([])
//   const classes = useStyles();
//   const transactionTableData = []


class SKUDetailsView extends React.Component {
// const SKUDetailsView = (props) => {
    


    constructor(props) {
        super(props);
        this.state = {
          testData: [],
          sku: "",          
        };
        this.setTransactionTableData = this.setTransactionTableData.bind(this)
      }


    componentDidMount = (props) => {
        


        console.log('mount it!', this.props.uid);



        var db = firebase.firestore();
        var projectId = this.props.projectId
        
        var transactionTableData = []

        db.collection(`customer/${projectId}/transactionLog`).where("uid", "==", this.props.uid).get()
        .then((snapshot) => {

            if (snapshot.docs[0].exists) {
                var jsonData = snapshot.docs[0].data();


                for(var i = 0; i < snapshot.docs.length; i++)
                {
                    var jsonData = snapshot.docs[i].data();
                    jsonData.id = snapshot.docs[i].id

                    transactionTableData.push({jsonData})
                }

            }
            
            console.log("Document successfully read!", jsonData);


            this.setState({testData: transactionTableData})

            this.setState({sku: jsonData.sku})


            console.log("transactionTableData: ", transactionTableData);

        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });











   }




  

  setTransactionTableData = (uid) => {


    // var db = firebase.firestore();
    // var projectId = this.props.projectId //this.context;
    

    // // db.collection(`customer/${projectId}/transactionLog`).where("sku", "==", "SSEB").get()
    // db.collection(`customer/${projectId}/transactionLog`).where("uid", "==", uid).get()
    // .then((snapshot) => {

    //     if (snapshot.docs[0].exists) {
    //         var jsonData = snapshot.docs[0].data();


    //         for(var i = 0; i < snapshot.docs.length; i++)
    //         {
    //             var jsonData = snapshot.docs[i].data();
    //             jsonData.id = snapshot.docs[i].id

    //             transactionTableData.push({jsonData})
    //         }

    //     }
        
    //     console.log("Document successfully read!", jsonData);

    //     // setTestData(transactionTableData)

    //     // this.testData = transactionTableData

    //     this.setState({testData: transactionTableData})

    //     console.log("transactionTableData: ", transactionTableData);

    // })
    // .catch(function(error) {
    //     console.error("Error writing document: ", error);
    // });

  }











render(){


  return (
    <div>
      <Box
        display="flex"
        justifyContent="flex-end"
        marginRight={20}>

        <Box
          marginRight={4.3}
          marginBottom={2}>
          <Box marginBottom={4.35}>
            {/* <Button variant='contained' onClick={() => this.setTransactionTableData(this.props.uid)}>Refresh Table</Button> */}

            <Box margin={1}>

                   <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h2"
                    >
                      {this.state.sku}
                    </Typography>


                    <MaterialTable
                      columns={[
                      { 
                          title: "Date", 
                          field: "jsonData.timeStamp",
                          defaultSort: "desc",
                          // customSort: (a, b) => a.jsonData.timeStamp - b.jsonData.timeStamp,
                          render: transactionTableData => <div>
                                                  {transactionTableData.jsonData.timeStamp.toDate().toDateString() + " at " + 
                                                  ('0' + transactionTableData.jsonData.timeStamp.toDate().getHours()).slice(-2) + ':' + 
                                                  ('0' + transactionTableData.jsonData.timeStamp.toDate().getMinutes()).slice(-2) +
                                                  " UTC"}
                                              </div>
                      },
                      { title: "Type", field: "jsonData.type" },
                      // { title: "Amount removed", field: "jsonData.quantityRemoved",},
                      { title: "Quantity Modified", field: "jsonData.quantityModified",},
                      { title: "Notes", field: "jsonData.notes",},
                      { title: "Unit Price", field: "jsonData.unitPrice",},
                      { title: "Balance ($)", field: "jsonData.currentBalance",},
                      { title: "User", field: "jsonData.userResponsible" },
                      ]}
                      data={this.state.testData}
                      options={{
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 20, 50, 75, 100 ],
                        sorting: true,
                        fixedColumns: {
                          // left: 1,
                          // right: 1
                        }
                      }}
                      title="Transaction History "
                      editable={{

                      }}
                  />

                  </Box>
          </Box>
          <Box>
          </Box>
        </Box>

      </Box>
      <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
          {/* <Button>Sales Destination Options</Button> */}
      </Box>
      <Box paddingY={2}>
      </Box>
      <Box>
      </Box>
    </div>
  );
}
};

SKUDetailsView.propTypes = {
  className: PropTypes.string
};

export default SKUDetailsView;
