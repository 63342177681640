import React from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from '@reach/router';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import GoogleIcon from 'src/icons/Google';
import { withFirebase } from 'src/components/Firebase';
import { useCookies } from 'react-cookie';

const SignUpForm = (props) => {

  const navigate = useNavigate()
  const [cookies] = useCookies(['joinData']);

  const handleGoogleSignIn = () => {
    var signUpPromise;
    const joinData = cookies.joinData;
    if (joinData) {
      signUpPromise = props.firebase.doGoogleSignInWithPopup(joinData.projectId, joinData.joinId);
    }
    else {
      signUpPromise = props.firebase.doGoogleSignInWithPopup();
    }

    signUpPromise
      .then(user => {
        navigate('/');
      })
      .catch(error => {
        console.error(error.message)
      });
  };

  const handleEmailSignIn = (email, password) => {
    var signInPromise;
    const joinData = cookies.joinData;
    if (joinData) {
      signInPromise = props.firebase.doEmailSignIn(email, password, joinData.projectId, joinData.joinId);
    }
    else {
      signInPromise = props.firebase.doEmailSignIn(email, password);
    }

    return signInPromise;
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        password: Yup.string().max(255).required('Password is required')
      })}
      onSubmit={(values, actions) => {
        handleEmailSignIn(values.email, values.password)
          .then(user => {
            actions.setSubmitting(false);
            navigate('/');
          })
          .catch(error => {
            const code = error.code;
            let errorField = 'email';
            if (code === "auth/wrong-password") {
              errorField = "password"
            }

            actions.setFieldError(errorField, error.message);
            actions.setSubmitting(false);
            console.error(error);
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          {/* <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              Sign in
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Sign in on the internal platform.
            </Typography>
          </Box> */}
          <Grid
            container
            spacing={3}
          >
        <Grid
          item
          xs={12}
          md={6}
        >
        {/* <Button
          fullWidth
          startIcon={<GoogleIcon />}
          onClick={handleGoogleSignIn}
          size="large"
          variant="contained"
        >
          Login with Google
        </Button> */}
      </Grid>
    </Grid>
    <Box
      mt={3}
      mb={1}
    >
      <Typography
        color="textPrimary"
        variant="h2"
        align="center"
      >
        Sign in
      </Typography>
      <Typography
        align="center"
        color="textSecondary"
        variant="body1"
      >
        with email address
      </Typography>
    </Box>
    <TextField
      error={Boolean(touched.email && errors.email)}
      fullWidth
      helperText={touched.email && errors.email}
      label="Email Address"
      placeholder="email address"
      margin="normal"
      name="email"
      onBlur={handleBlur}
      onChange={handleChange}
      type="email"
      value={values.email}
      variant="outlined"
    />
    <TextField
      error={Boolean(touched.password && errors.password)}
      fullWidth
      helperText={touched.password && errors.password}
      label="Password"
      placeholder="password"
      margin="normal"
      name="password"
      onBlur={handleBlur}
      onChange={handleChange}
      type="password"
      value={values.password}
      variant="outlined"
    />
    <Box my={2}>
      <Button
        color="primary"
        disabled={isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        Sign in now
      </Button>
    </Box>
    <Typography
      color="textSecondary"
      variant="body1"
    >
      Don&apos;t have an account?
    {' '}
    <Link
      component={RouterLink}
      to="/register"
      variant="h6"
    >
      Sign up
    </Link>
  </Typography>
</form>
      )}
</Formik>
)
}

export default withFirebase(SignUpForm);
