import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ProjectContext from 'src/components/ProjectContext';



import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import MaterialTable from "material-table";





import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import { divide } from 'lodash';

export default function AuditQuantityDialog(rowData) {
    

  const [open, setOpen] = React.useState(false);
  const projectId = React.useContext(ProjectContext)

  const handleClickOpen = () => {

    var db = firebase.firestore();

    db.collection(`customer/${projectId}/inventory_raw_goods`).doc(rowData.value.uid).get()
    .then(function(doc) {

        if (doc.exists) {
            var jsonData = doc.data();
        }
        console.log("Document successfully read!");
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });


    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  

  return (
    <div>
      
      <Button variant="contained" color="primary" onClick={handleClickOpen} >
        More Quantity Info
      </Button>
      
      
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth = {'md'}>
        <DialogTitle id="form-dialog-title">Audit Item</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Edit quantity for item at specific unit price
            </DialogContentText>

            <Box>
                <MaterialTable
                columns={[
                { 
                    title: "Date Added", 
                    field: "timeStamp",
                    render: rowData => <div>
                                            {rowData.timeStamp.toDate().toDateString() + " at " + 
                                            ('0' + rowData.timeStamp.toDate().getHours()).slice(-2) + ':' + 
                                            ('0' + rowData.timeStamp.toDate().getMinutes()).slice(-2) +
                                            " UTC"}
                                        </div>
                },
                { title: "Unit Price ($)", field: "unitPrice" },
                { title: "Unit Quantity", field: "units",},
                ]}
                data={rowData.value.priceLog}
                title="" //"Item Quantity and Unit Price Information"
                editable={{

                    //Within these operations we are using the timestamp to identify items

                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
        
                                var db = firebase.firestore();

                                db.collection(`customer/${projectId}/inventory_raw_goods`).doc(rowData.value.uid).get().then(function(doc) {
                                    if (doc.exists) {
              
                                        var jsonData = doc.data();

                                        var quantitySum = 0
                                        var cogsSum = 0

                                        for(let i = 0; i < jsonData.priceLog.length; i++)
                                        {

                                            var dbSeconds = jsonData.priceLog[i].timeStamp.seconds 
                                            var dbNanoSeconds = jsonData.priceLog[i].timeStamp.nanoSeconds

                                            var newDataSeconds = newData.timeStamp.seconds 
                                            var newDataNanoSeconds = newData.timeStamp.nanoSeconds

                                            if(dbSeconds == newDataSeconds && dbNanoSeconds == newDataNanoSeconds)
                                            {
                                                jsonData.priceLog[i].units = Number(newData.units)
                                                jsonData.priceLog[i].unitPrice = Number(newData.unitPrice)
                                            }
                                            
                                            //Sum all units in priceLog array
                                            quantitySum += jsonData.priceLog[i].units
                                            //Multiple units * unitPrice and sum to get cost of goods for all items
                                            //within the price log
                                            cogsSum += (jsonData.priceLog[i].units * jsonData.priceLog[i].unitPrice)
                                        }

                                        jsonData.quantity = quantitySum
                                        jsonData.totalPrice = Math.round(cogsSum * 100)/100
              
                                        db.collection(`customer/${projectId}/inventory_raw_goods`).doc(rowData.value.uid).update(jsonData)
                                        .then(function() {
                                            console.log("Document successfully written!");
                                        })
                                        .catch(function(error) {
                                            console.error("Error writing document: ", error);
                                        });
                                        
              
                                    } else {
                                        console.log("No such document!");
                                    }
                                    }).catch(function(error) {
                                        console.log("Error getting document:", error);
                                    });
            
                                resolve();
                            }, 1000);
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                               
                                var db = firebase.firestore();

                                db.collection(`customer/${projectId}/inventory_raw_goods`).doc(rowData.value.uid).get().then(function(doc) {
                                    if (doc.exists) {
              
                                        var jsonData = doc.data();

                                        var quantitySum = 0
                                        var cogsSum = 0

                                        // var resultArray = jsonData.priceLog //[]

                                        for(let i = 0; i < jsonData.priceLog.length; i++)
                                        {
                                            
                                            var dbSeconds = jsonData.priceLog[i].timeStamp.seconds 
                                            var dbNanoSeconds = jsonData.priceLog[i].timeStamp.nanoSeconds

                                            var newDataSeconds = oldData.timeStamp.seconds 
                                            var newDataNanoSeconds = oldData.timeStamp.nanoSeconds

                                            if(dbSeconds == newDataSeconds && dbNanoSeconds == newDataNanoSeconds)
                                            {
                                                //delete row
                                                jsonData.priceLog.splice(i, 1);
                                            }
                                            
                                            //Sum all units in priceLog array
                                            quantitySum += jsonData.priceLog[i].units
                                            //Multiple units * unitPrice and sum to get cost of goods for all items
                                            //within the price log
                                            cogsSum += (jsonData.priceLog[i].units * jsonData.priceLog[i].unitPrice)
                                            
                                        }

                                        jsonData.quantity = quantitySum
                                        jsonData.totalPrice = Math.round(cogsSum * 100)/100
              
                                        db.collection(`customer/${projectId}/inventory_raw_goods`).doc(rowData.value.uid).update(jsonData)
                                        .then(function() {
                                            console.log("Document successfully written!");
                                        })
                                        .catch(function(error) {
                                            console.error("Error writing document: ", error);
                                        });
                                        
              
                                    } else {
                                        console.log("No such document!");
                                    }
                                    }).catch(function(error) {
                                        console.log("Error getting document:", error);
                                    });
            
                                resolve();
                            }, 1000);
                        })
                }}
            />
            </Box>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
          
        </Dialog>
        
    </div>
  );
}
