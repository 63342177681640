import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CSVLink, CSVDownload } from "react-csv";
import CSVReader from 'react-csv-reader'
import Paper from '@material-ui/core/Paper';

import ProjectContext from 'src/components/ProjectContext';

import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";

import { v4 as uuid } from 'uuid';


export default function ImportDialog() {
  const [open, setOpen] = React.useState(false);

  const [buttonDisabled, setButtonDisabledValues] = React.useState(true)

  const projectId = React.useContext(ProjectContext)

  const [uploadedCSVDataValues, setcsvDataValues] = React.useState({
    uploadedCSVData: {}, //[],
  });

  const [fileInfoValues, setfileInfoValues] = React.useState({
    fileInfo: "",
  });

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header =>
      header
        .toLowerCase()
        .replace(/\W/g, '_')
  }

  const handleClickOpen = () => {
    setOpen(true);
    setButtonDisabledValues(true)
  };

  const handleClose = () => {
    setOpen(false);
  };


  
  const handleBulkEdit = () => {

    var db = firebase.firestore();

    // Get a new write batch
    var batch = db.batch();

    uploadedCSVDataValues.uploadedCSVData.forEach(element => {


      if(element[0] != "SKU"){
        console.log("this is greater than index 0", element)



        // var ref = db.collection(`customer/${projectId}/inventory_raw_goods`).doc(element[0]);
        var ref = db.collection(`customer/${projectId}/inventory_raw_goods`).doc(element.uid);
        batch.set(ref, 
        {
          uid: element.uid, //element[0],
          sku: element.sku, //element[0],
          name: element.name, //element[1],
          quantity: Number(element.quantity), //Number(element[2]),
          location: element.location, //element[3],
          unitPrice: Number(element.unitPrice), //Number(element[4]),
          totalPrice:  Math.round(Number(Number(element.unitPrice) * Number(element.quantity)) * 100)/100, //Number(Number(element[4]) * Number(element[2])),
          // Math.round(cogsSum * 100)/100
          category: element.category, //element[5],
          notes: element.notes, //element[6],
          discontinued: (element.discontinued  === 'TRUE'),
          lowInventoryAlertValue: 10,
          emailAlert: "example@business.com",
          priceLog: [
            {
              unitPrice: Number(element.unitPrice), //Number(element[4]),
              units: Number(element.quantity), // Number(element[2]),
              timeStamp: firebase.firestore.Timestamp.fromDate(new Date())
            }],
        });
      }
    });



    // Commit the batch
    batch.commit().then(function () {
      // ...
    });
    


    setOpen(false);
  }

  const handleImport = () => {

    console.log("uploadedCSVData", uploadedCSVDataValues.uploadedCSVData)

    var db = firebase.firestore();

    // Get a new write batch
    var batch = db.batch();

    uploadedCSVDataValues.uploadedCSVData.forEach(element => {


      if(element[0] != "SKU"){
        console.log("this is greater than index 0", element)


        var newDocUID = uuid()


        // var ref = db.collection(`customer/${projectId}/inventory_raw_goods`).doc(element[0]);
        var ref = db.collection(`customer/${projectId}/inventory_raw_goods`).doc(newDocUID);
        batch.set(ref, 
        {
          uid: newDocUID, //element[0],
          sku: element.sku, //element[0],
          name: element.name, //element[1],
          quantity: Number(element.quantity), //Number(element[2]),
          location: element.location, //element[3],
          unitPrice: Number(element.unitPrice), //Number(element[4]),
          totalPrice: Math.round(Number(Number(element.unitPrice) * Number(element.quantity)) * 100)/100, //Number(Number(element[4]) * Number(element[2])),
          category: element.category, //element[5],
          notes: element.notes, //element[6],
          discontinued: (element.discontinued  === 'TRUE'), //element.discontinued
          lowInventoryAlertValue: 10,
          emailAlert: "example@business.com",
          priceLog: [
            {
              unitPrice: Number(element.unitPrice), //Number(element[4]),
              units: Number(element.quantity), // Number(element[2]),
              timeStamp: firebase.firestore.Timestamp.fromDate(new Date())
            }],
        });
      }
    });



    // Commit the batch
    batch.commit().then(function () {
      // ...
    });
    


    setOpen(false);
  };



  const handleUploadFile = (data, fileInfo) => {


    console.log("fileInfo: ",fileInfo)
    console.log("data: ", data)

    setcsvDataValues({uploadedCSVData: data})
    setfileInfoValues({fileInfo: fileInfo})


    setButtonDisabledValues(false)


    
    
  };

  const csvData = [
    ["sku", "name", "quantity", "location", "unitPrice", "category", "notes", "discontinued", "lowInventoryAlertValue", "emailAlert"],
    ["TESTSKU1", "TestName1", "100", "Shelf 2, Row 1", "2.1", "COGS", "Example Note", "true", "10", "example@business.com"],
    ["TESTSKU2", "TestName2", "243", "Shelf 3, Row 1", "3.21", "COGS", "Example Note", "false", "10", "example@business.com"],
    ["TESTSKU3", "TestName3", "19", "Shelf 3, Row 1", "1.13", "COGS", "Example Note", "true", "10", "example@business.com"]
  ];

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Import Bulk Items
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Import/Edit from CSV</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Importing/Editng bulk items is easy
          </DialogContentText>
          <DialogContentText>
            1 Download Example  
            <CSVLink data={csvData}>Template</CSVLink>
          </DialogContentText>
          <DialogContentText>
            2 Fill in data. We recommend using excel or google sheets
          </DialogContentText>
          <DialogContentText color="black">
            Important! Don't move or modify the top row of the csv and be sure that the data follows the example format
          </DialogContentText>
          <Paper variant="outlined" style={{width: 301, height: 101}}>
            <img
                src={require('./CSVImportImage.png')}
                style={{width: 300, height: 100}}
                />
          </Paper>
          <DialogContentText>
            3 After filling in the data, save the changes and use the Choose File button below to select the file to import
          </DialogContentText>
          <DialogContentText color="black">
            Important! Be sure to save your file as a .csv
          </DialogContentText>
          <CSVReader parserOptions={{header: true}} onFileLoaded={(data, fileInfo) => handleUploadFile(data, fileInfo)} />
          {/* <CSVReader
            cssClass="csv-reader-input"
            label="Select CSV with secret Death Star statistics"
            onFileLoaded={handleUploadFile()}
            // onError={this.handleDarkSideForce}
            parserOptions={papaparseOptions}
            inputId="ObiWan"
            inputStyle={{color: 'red'}}
          /> */}

        </DialogContent>
        <DialogActions>        
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {/* Consider adding this feature back in the future <Button disabled={buttonDisabled} onClick={handleBulkEdit} color="primary" variant="contained">
            Import Bulk Edit File
          </Button> */}
          <Button disabled={buttonDisabled} onClick={handleImport} color="primary" variant="contained">
            Import New Data File
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
