import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import data from './data';
import { withFirebase } from 'src/components/Firebase';
import ProjectContext from 'src/components/ProjectContext';
import "firebase/analytics";
import "firebase/firestore";
import { withPayment } from 'src/components/PaymentContext';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));


const CustomerListView = (props) => {


  const classes = useStyles();
  const [customers, setCustomers] = useState(data); 
  const projectId = useContext(ProjectContext);

  useEffect(() => {

    var db = props.firebase.firestore;
    

    db.collection(`customer/${projectId}/inventory_raw_goods`)
    .onSnapshot(function(querySnapshot) {
        var cities = [];
        querySnapshot.forEach(function(doc) {
            cities.push(doc.data());
        });
        setCustomers(cities)
    });
  },[projectId, props.firebase])

  return (
    <Page
      className={classes.root}
      title="Inventory Simplified"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results customers={customers} />
        </Box>
      </Container>
    </Page>
  );
};


export default withPayment(withFirebase(CustomerListView));
