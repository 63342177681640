import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from 'src/components/Firebase';
import ProjectContext from 'src/components/ProjectContext';
import RoleSelector from './RoleSelector';
import AddUserForm from './AddUserForm';
import MaterialTable from 'material-table';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const UserManagement = (props) => {

  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const projectId = useContext(ProjectContext);

  const handleUserAddition = (pendingUserEmail) => {
    const newUsersArray = users.concat([]);
    newUsersArray.push({
      'email': pendingUserEmail,
      'pending': true
    });
    setUsers(newUsersArray);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRemoveUser = (rowData) => {
    const db = props.firebase.firestore;
    const projectDocRef = db.collection('customer').doc(projectId);
    let roleName;
    if (rowData.role === 'Admin') { roleName = 'admins' }
    else if (rowData.role === 'User') { roleName = 'users' }
    else { throw "User has invalid role. Please contact support." }

    return db.runTransaction(transaction => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(projectDocRef).then(projectDoc => {
        if (!projectDoc.exists) {
            throw "Document does not exist!";
        }

        const newAdminsList = projectDoc.data()[roleName].filter(userId => userId !== rowData.id);

        transaction.update(projectDocRef, { admins: newAdminsList });
      });
    }).then(function() {
      const newUserList = users.filter(u => u.id !== rowData.id);
      setUsers(newUserList);
      console.log("Transaction successfully committed!");
    }).catch(function(error) {
      console.log("Transaction failed: ", error);
    });
  };

  const handleAddUser = (rowData) => {
    return props.firebase.addUserToProject(projectId, rowData.email, rowData.role)
      .then(pendingUserEmail => {
        const newUsersArray = users.concat([]);
        newUsersArray.push({
          'email': pendingUserEmail,
          'pending': true
        });
        setUsers(newUsersArray);
      })
      .catch(error => { 
        console.log(error);
      });
  };

  useEffect(() => {


    console.log("Manage users")



    props.firebase.getUsers(projectId)
      .then(users => {
        setUsers(users);
      })
  }, [props.firebase, projectId]);


  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div>
      <h1>User management</h1>
      <MaterialTable
        columns={[
          { title: 'First Name', field: 'first_name', editable: 'never' },
          { title: 'Last Name', field: 'last_name', editable: 'never' },
          { title: 'Email', field: 'email', editable: 'onAdd' },
          { title: 'Role', field: 'role', lookup: {'admin': 'Admin', 'user': 'User'}}
        ]}
        data={users}
        editable={{
          onRowDelete: handleRemoveUser,
          onRowAdd: handleAddUser
        }}
        options={{
          actionsColumnIndex: -1
        }}
      />
    </div>
  );
};

export default withFirebase(UserManagement);
