import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import MaterialTable from 'material-table';

import ProjectContext from 'src/components/ProjectContext';

import Checkbox from '@material-ui/core/Checkbox';

import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";


export default function SalesOptionsDialog() {

  
  const [open, setOpen] = React.useState(false);
  const projectId = React.useContext(ProjectContext)

  const [salesDestinationValues, setsalesDestinationValues] = React.useState({
    salesDestination: '',
  });

  const [collectionsDocDataStateValues, setcollectionsDocDataState] = React.useState({
    collectionsDocDataState: [],
  })

  const [disableAddCustomerButton, setDisableAddCustomerButton] = React.useState(true)


  // const isSelected = (name) => selected.indexOf(name) !== -1;

  // const handleSelection = (event, val) => {
  //   console.log('selected', event, val)

  //   const selectedIndex = selected.indexOf(val);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, val);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }
  // }


  const columns = [
    { title: 'Customer Name', field: 'name' },
  ]


  const handleClickOpen = () => {
    
    var db = firebase.firestore();
    var refs = db.doc(`customer/${projectId}/salesReport/online`);
    var promise = new Promise( (resolve) => {

      db.collection(`customer/${projectId}/salesReport`).get()
        .then(snapshot => {
          resolve(snapshot)
          var collectionsDocData = snapshot.docs.map(doc => {
            return {
              name: doc.id,
              data: doc.data()
            }
          });
          setcollectionsDocDataState({collectionsDocDataState: collectionsDocData})
        }).catch(error => {
          console.error("Error writing document: ", error);
        });
    });
    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
  };

  const setsalesDestinationFunction = (customerName) => {
    setsalesDestinationValues({salesDestination: customerName})
    if(customerName.length == 0) {
      setDisableAddCustomerButton(true);
    }
    else {
      setDisableAddCustomerButton(false);
    }
  }

  const addSalesDestination = () => {

    const data = JSON.parse(JSON.stringify(collectionsDocDataStateValues.collectionsDocDataState))
    var newDestination = {
      totalItemsRemoved: 0 ,
      totalPurchasePrice: 0, //salesReportSnapshot.data().totalPurchasePrice + 10,            
      transactions: firebase.firestore.FieldValue.arrayUnion({
        "timeStamp": firebase.firestore.Timestamp.fromDate(new Date()), //db.Timestamp.fromDate(new Date()),
        "description": "bulk",
        "name": "",
        "subTotal": 0.0,
        "quantity": 0,
        "sku": 'id', 
        "transactionType": "Removal",
      }),
    }

    // Consider uncommenting this and trying to get it working to save $$$ on api calls
    // data.push({name: salesDestinationValues.salesDestination, data: newDestination})
    // setcollectionsDocDataState({collectionsDocDataState: data})

    var db = firebase.firestore();

    if (salesDestinationValues.salesDestination.length != 0) {
      db.collection(`customer/${projectId}/salesReport`).doc(salesDestinationValues.salesDestination).set(newDestination)
      .then(function() {
          console.log("Document successfully written!");
          //Consider not using this as the above comment suggests. This uses an extra api call
          //This could rack up in $$$ if we aren't careful
          handleClickOpen()
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
    }
  }

  return (
    <div>
      
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Sales Destination Options
      </Button>
      
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth = {'md'}>
        <DialogTitle id="form-dialog-title">Sales Destination Options</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add or remove customers
            </DialogContentText>

            <TextField
              autoFocus
              margin="dense"
              id="salesDestination"
              label="Customer Name"
              type="salesDestination"    
              name="salesDestination"
            
              onChange={(event) => setsalesDestinationFunction(event.target.value)} 
            />
            <Button variant="contained" color="primary" disabled={disableAddCustomerButton} onClick={addSalesDestination}>Add Customer</Button>

              <MaterialTable
                title="Sales Destinations"
                columns={columns}
                data={collectionsDocDataStateValues.collectionsDocDataState} //{data}
                editable={{
                  onRowUpdate: (newData, oldData) => new Promise((resolve) => {
                    console.log(oldData);
                    console.log(newData);
                    console.log(collectionsDocDataStateValues.collectionsDocDataState)
                    const item = collectionsDocDataStateValues.collectionsDocDataState.find(item => item.id === oldData.id);
                    var db = firebase.firestore();

                    // First, delete the old sales report destination
                    db.collection(`customer/${projectId}/salesReport`).doc(oldData.name).delete()
                      .then(() => {
                        // Then add the new sales report destination with the "newData".
                        return db.collection(`customer/${projectId}/salesReport`).doc(newData.name).set(newData)
                      })
                      .then(() => {
                        console.log("Document successfully added");

                        // Then update the state array
                        let newArray = JSON.parse(JSON.stringify(collectionsDocDataStateValues.collectionsDocDataState));
                        let idx = newArray.findIndex(x => x.id === oldData.id);
                        newArray[idx] = newData;
                        setcollectionsDocDataState({
                          collectionsDocDataState: newArray
                        });
                        resolve();
                      }).catch(function(error) {
                          console.error("Error removing document: ", error);
                      });
                  }),
                  onRowDelete: oldData =>
                    new Promise((resolve) => {
                      console.log("try to delete:")
                      setTimeout(() => {
                        resolve();
                        var db = firebase.firestore();
          
                        console.log("deleting: ", oldData.name)
                        db.collection(`customer/${projectId}/salesReport`).doc(oldData.name).delete().then(function() {

                          console.log("Document successfully deleted!");

                          const data = JSON.parse(JSON.stringify(collectionsDocDataStateValues.collectionsDocDataState))
                          data.splice(data.findIndex((element) => element.name == oldData.name),1)
                          setcollectionsDocDataState({collectionsDocDataState: data})

                        }).catch(function(error) {
                            console.error("Error removing document: ", error);
                        });
                      }, 600);
                    }),
                  }}
                />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
          
        </Dialog>
        
    </div>
  );
}

