import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import {
    Avatar,
    Box,    
    Grid,
  } from '@material-ui/core';
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import ProjectContext from 'src/components/ProjectContext';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    backgroundColor: 'green', //colors.green[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: 'green'//colors.green[900]
  },
  differenceValue: {
    color: 'green',
    marginRight: 10 //theme.spacing(1)
  }
});

class LowInventoryCard extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            totalPurchasePrice: 0,
            totalItemsRemoved: 0,
            expanded: false,
            collectionsDocDataState: [],
        };
        this.handleExpandClick = this.handleExpandClick.bind(this)
    }

    handleExpandClick() {
        this.setState({expanded: !this.state.expanded});
    }

    
    componentDidMount() {
        var db = firebase.firestore();
        const projectId = this.context;
    
        var refs = db.doc(`customer/${projectId}/inventory_raw_goods/CRCEB`);
      
        var callback = this.promise;
    
        refs.get(callback)
    
        var promise = new Promise( (resolve) => {
        

            db.collection(`customer/${projectId}/inventory_raw_goods`).get()
          .then(function(snapshot)
          {        
    
            resolve(snapshot)
    
          }).catch(function(error) {
                console.error("Error writing document: ", error);
          });
        });
        
        promise.then( result => {        
            var collectionsDocData = result.docs.map(doc => {
            
              return {
                name: doc.id,
                data: doc.data()
              }
            });
    
            this.setState({collectionsDocDataState: collectionsDocData})
    
    
        }, function(error) {
            console.log("Error")
        });
    }

    render() {
        //The code here in this render probably can get optimized way better
        //much like most of this project
        //But it seems to be working right now
        var transactionTables = []

        var quantityArray = []

        var min = Math.min(...quantityArray)

        
        var lowInenvotryItemName = ""
        var reorderCost = 0
        var lastKnownUnitPrice = 0

        if(this.state.collectionsDocDataState)
        {
          transactionTables = this.state.collectionsDocDataState.map(collection => 
            {
                quantityArray.push(collection.data.quantity)
                min = Math.min(...quantityArray)

                if(min === collection.data.quantity)
                {
                    lowInenvotryItemName = collection.data.name
                    reorderCost = collection.data.unitPrice * 100
                    lastKnownUnitPrice = collection.data.unitPrice
                }
            })
        }
        return (
            <Card >
              <CardContent>
                <Grid
                container
                // justify="space-between"
                spacing={3}
                >
                <Grid item>
                    <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                    >
                    LOWEST INVENTORY
                    </Typography>
                    <Typography
                    color="textPrimary"
                    variant="h4"
                    >
                        {lowInenvotryItemName}
                    </Typography>
                </Grid>
                <Grid item>
                    <Avatar style={useStyles.avatar}>
                      <CallToActionIcon />
                    </Avatar>
                </Grid>
                </Grid>
                <Box
                  mt={2}
                  display="flex"
                  alignItems="center"
                  paddingRight={2}>
                  <Typography variant="body2" >
                    Items left: {min}
                  </Typography>
                </Box>
                <Box>
                  Last recorded unit price: ${lastKnownUnitPrice}
                </Box>
                <Box>
                   Reorder Cost: ${Math.round((reorderCost + Number.EPSILON) * 100) / 100} for 100 units
                </Box>
                </CardContent>
            </Card>
          )
    }
}

LowInventoryCard.contextType = ProjectContext;

export default LowInventoryCard;

