import { v4 as uuid } from 'uuid';


export default [

  {
    id: uuid(),
    address: {
      country: '',
      state: '',
      city: '',
      street: ''
    },
    avatarUrl: '',
    createdAt: 1555016400000,
    email: '',
    name:  '',
    phone: ''
  },
  
];
