import React from "react";
import ReactToPrint from "react-to-print";

import AverySticker from 'src/views/customer/CustomerListView/AverySticker.js';
import _ from "lodash";

class ComponentToPrint extends React.PureComponent {

  constructor(props) {
    super(props);

    const sizeOfPage = 10
    const numItemsPerPage = sizeOfPage / 2; // 2 Of the same items per page
    const customerList = _.cloneDeep(props.customers);
    const pageOfItems = [];

    while( customerList.length > 0) {
      pageOfItems.push(customerList.splice(0, numItemsPerPage));
    }

    this.state = {
      pageOfItems: pageOfItems
    }

    console.log(pageOfItems);
  }

  render() {
    return (
      <div className="print-container" style={{ margin: "0", padding: "0"}}>
        {this.state.pageOfItems.map((itemArray, index) =>
          <>
            <div style={{ marginTop: "0.5in", marginBottom: "0.5in", marginLeft: "0.15in", marginRight: "0.15in", pageBreakBefore: "always" }}>
              {itemArray.map((val, index) =>
                <div style={{ display: "flex", height: "2in" }}>
                <AverySticker
                  key={index}
                  sku={val.sku}
                  showSku={val.stickerPrintSettings ? val.stickerPrintSettings.showName : true}
                  location={val.location}
                  showLocation={val.stickerPrintSettings ? val.stickerPrintSettings.showLocation : true} />
                <div style={{ width: "0.18in" }}/>
                <AverySticker
                  key={index}
                  sku={val.sku}
                  showSku={val.stickerPrintSettings ? val.stickerPrintSettings.showName : true}
                  location={val.location}
                  showLocation={val.stickerPrintSettings ? val.stickerPrintSettings.showLocation : true} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

class PrintQRCode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
      }
      
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => <a href="#print">Print this out!</a>}
          content={() => this.componentRef}
        />
        <ComponentToPrint customers={this.props.customers}  ref={el => (this.componentRef = el)} />
      </div>
    );
  }
}

export default PrintQRCode;
