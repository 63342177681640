import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
        fontSize: 15,
    },
    sku: {
        width: '35%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    description: {
        width: '20%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    transactionType: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    subTotal: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });

  Number.prototype.countDecimals = function () {
    if(Math.floor(this.valueOf()) === this.valueOf()) return 0;
    return this.toString().split(".")[1].length || 0; 
}

const InvoiceTableRow = ({transactionItems}) => {

    //Need to figure out how to sum quantity and subtotal
    //and filter duplicate items
    var transactionItemsCopy = JSON.parse(JSON.stringify(transactionItems))

    var uniqueItems = transactionItemsCopy.filter((thing, index, self) =>
        index === self.findIndex((t) => (
        t.sku === thing.sku // && t.name === thing.name        
        ))
    )

    //Zero out quantity field in array. This is required for 
    //Summing the array correctly in the below for loop
    uniqueItems.map( x => x.quantity = 0)
    uniqueItems.map( x => x.subTotal = 0.0)



    for(var i = 0; i<transactionItems.length; i++)
    {
        for(var j = 0; j < uniqueItems.length; j++)
        {
            if(transactionItems[i].sku === uniqueItems[j].sku)
            {
                uniqueItems[j].quantity += transactionItems[i].quantity
                uniqueItems[j].subTotal += transactionItems[i].subTotal
                

                //Need to multiply the quantity by subtotal


                if(uniqueItems[j].subTotal.countDecimals() > 2)
                {
                    uniqueItems[j].subTotal = (Math.round(uniqueItems[j].subTotal * 100)/100)
                    console.log("uniqueItems[j].subTotal: ", uniqueItems[j].subTotal)
                }
            }
        }
    }

    const rows = uniqueItems.map( item => 
        <View style={styles.row} key={item.id}>
            <Text style={styles.sku}>{item.sku}</Text>
            <Text style={styles.description}>{item.name}</Text>
            <Text style={styles.qty}>{item.quantity}</Text>
            <Text style={styles.transactionType}>{item.transactionType}</Text>
            <Text style={styles.subTotal}>$ {item.subTotal}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
export default InvoiceTableRow
