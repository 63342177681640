import React from 'react';
import { AuthUserContext, withAuthentication } from 'src/components/Session';
import ProjectList from './ProjectList';

const ProjectSelection = (props) => {

  return (
      <AuthUserContext.Consumer>
        {user => 
          <ProjectList user={user}/>
        }
      </AuthUserContext.Consumer>
  );
};

export default withAuthentication(ProjectSelection);
