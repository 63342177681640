import React, {Fragment} from 'react';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
/* import { StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
  row: {
      flexDirection: 'row',
      borderBottomColor: 'black',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 24,
      fontStyle: 'bold',
      fontSize: 15,
  },
  sku: {
      width: '35%',
      textAlign: 'left',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingLeft: 8,
  },
  description: {
      width: '20%',
      textAlign: 'left',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingLeft: 8,
  },
  qty: {
      width: '10%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: 'right',
      paddingRight: 8,
  },
  transactionType: {
      width: '20%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: 'right',
      paddingRight: 8,
  },
  subTotal: {
      width: '15%',
      textAlign: 'right',
      paddingRight: 8,
  },
});*/


const TransactionTable = ({destinationName,transactionItems}) => {

    return (
        <Fragment>
            <InvoiceTableHeader destinationName={destinationName}  />
            <InvoiceTableRow transactionItems={transactionItems} />
        </Fragment>
    )
};
  
export default TransactionTable
