import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  Grid,
  Card,
  CardContent,
} from '@material-ui/core';
import {
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  Users as UsersIcon,
  Monitor as MonitorIcon
} from 'react-feather';
import NavItem from './NavItem';
import { withAuthentication, AuthUserContext } from 'src/components/Session';
import { withFirebase } from 'src/components/Firebase';
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import ProjectContext from 'src/components/ProjectContext';

const items = [
  // {
  //   href: 'dashboard',
  //   icon: MonitorIcon,
  //   title: 'Dashboard'
  // },
  {
    href: 'inventory',
    icon: ShoppingBagIcon,
    title: 'Inventory'
  },
  {
    href: 'user-management',
    icon: UsersIcon,
    title: 'User Management'
  },
  {
    href: 'account',
    icon: UserIcon,
    title: 'Account'
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile, ...props }) => {
  const classes = useStyles();
  const location = useLocation();

  const [projects, setProjects] = useState([]);

  const [projectName, setProjectName] = useState([]);

  
  useEffect(() => {

    var db = firebase.firestore();

    db.doc(`customer/${props.projectId}`).get().then(function(snapshot)
    {
      setProjectName(snapshot.data().name)

    }).catch(function(error) {
      console.error("Error writing document: ", error);
    });


  }, [props.user]);

  const content = (
    <AuthUserContext.Consumer>
      {user => 
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            p={2}
          >
            <Typography
              className={classes.name}
              color="textPrimary"
              variant="h3"
              style={{padding: 10}}
            >
              {projectName} 
            </Typography>
            <Typography
              className={classes.name}
              color="textSecondary"
              variant="h5"
            >
              {user ? user.first_name : ""} {user ? user.last_name : ""}
            </Typography>
            <Grid item xs={12}>
              {projects.map((project) => (
                <Card
                  // onClick={(e) => selectProject(project.id, project.name, e)}
                  key={project.id}
                  className={classes.root}
                  boxShadow={0}
                  style={{ 
                    backgroundColor: '#e3eaef', 
                    margin: 10}}
                  >
                <CardContent align='center'>
                  {project.name}
                </CardContent>
              </Card>
              ))}
            </Grid>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {user ? user.jobTitle : ""}
            </Typography>
          </Box>
          <Divider />
          <Box p={2}>
            <List>
              {items.map((item) => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              ))}
            </List>
          </Box>
          <Box flexGrow={1} />
          <Box
            p={2}
            m={2}
            bgcolor="background.dark"
          >
          </Box>
        </Box>
      }
    </AuthUserContext.Consumer>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default withAuthentication(NavBar);
