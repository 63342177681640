import React, {useContext} from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import { navigate } from '@reach/router';
import  PaymentContext  from './context';
import ProjectContext from 'src/components/ProjectContext';
import { withAuthentication } from '../Session';

 
const withPayment = Component => {


  class WithPayment extends React.Component {
    constructor(props) {
      super(props);
 
      

      this.state = {
        user: null,
      };
    }
 
    
    
    componentDidMount() {


    var db = this.props.firebase



    
    db.firestore.collection('customer')
    .doc(this.props.projectId)
    .collection('subscriptions')
    .where('status', 'in', ['trialing', 'active'])
    .onSnapshot((result) => {

        console.log("result", result)

        if (result.empty) {
            
            console.log("result.empty")
            navigate(`/app/${this.props.projectId}/account`)
        }
    }, (error)  => { 
            console.log("error")
            navigate(`/app/${this.props.projectId}/account`)
    })

    }

 
    render() {
      return (
        
              <PaymentContext.Provider value={this.state.user}>
                <Component {...this.props} />
              </PaymentContext.Provider>
        
      );
    }
  }
 
  

  return withAuthentication(WithPayment);
};

withPayment.contextType = ProjectContext

export default withPayment;

