import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ProjectContext from 'src/components/ProjectContext';



import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";

export default function EditItemFormDialog(skuForRow) {
  const [open, setOpen] = React.useState(false);
  const projectId = React.useContext(ProjectContext)


  const [lowInventoryAlertValues, setLowInventoryAlertValue] = React.useState({
    alertFloorValue: 100,
  });

  const [emailValues, setEmailAddressValues] = React.useState({
    email: 'purchasing@company.com',
  });

  const handleClickOpen = () => {

    var db = firebase.firestore();

    db.collection(`customer/${projectId}/inventory_raw_goods`).doc(skuForRow.value).get()
    .then(function(doc) {

        if (doc.exists) {
            var jsonData = doc.data();

            console.log("set values")
            setLowInventoryAlertFunction(Number(jsonData.lowInventoryAlertValue))
            setEmailAddressFunction(jsonData.emailAlert)
            console.log("after set values")
        }
        

        console.log("Document successfully read!");
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });


    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const setLowInventoryAlertFunction = (data) => {
    console.log("setLowInventoryAlertFunction: ", data)
    setLowInventoryAlertValue({alertFloorValue: Number(data)})
  }

  const setEmailAddressFunction = (data) => {
    console.log("email: ", data)
    setEmailAddressValues({email: data})
  }

  const handleAddItem = (formData) => {
    console.log("HandleAddItem")

    console.log("skuForRow", skuForRow.value)

    console.log("lowInventoryAlertValues.alertFloorValue: ", lowInventoryAlertValues.alertFloorValue)
    console.log("emailValues.value: ", emailValues.email)


    var db = firebase.firestore();

    db.collection(`customer/${projectId}/inventory_raw_goods`).doc(skuForRow.value).update({
      "lowInventoryAlertValue": lowInventoryAlertValues.alertFloorValue, 
      "emailAlert": emailValues.email,
    })
    .then(function() {
        console.log("Document successfully written!");
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });


    setOpen(false);
    
  };
  
  

  return (
    <div>
      
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Edit Alert
      </Button>
      
      
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Email Alert Options</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Email alerts will be sent when this item's stock goes below
              the set number
            </DialogContentText>
            
            <TextField
              autoFocus
              margin="dense"
            //   id="sku"
              label="Set low inventory alert at:"
              type="number"    
              value={lowInventoryAlertValues.alertFloorValue}
              //name="SKU"
              fullWidth={true}
              InputProps={{ inputProps: { min: 0, max: 10000000 } }}
              
            
              onChange={(event) => setLowInventoryAlertFunction(event.target.value)} 
            />
            <TextField            
              margin="dense"
              id="name"
              label="Email alert to"
              value={emailValues.email}//"purchasing@company.com"
              type="name"
              fullWidth
              onChange={(event) => setEmailAddressFunction(event.target.value)} 
            />
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddItem} color="primary">
              Add
            </Button>
          </DialogActions>
          {/* </form> */}
          
        </Dialog>
        
    </div>
  );
}
