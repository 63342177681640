import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import { withFirebase } from 'src/components/Firebase';
import ProjectContext from 'src/components/ProjectContext';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const AddUserForm = (props) => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState();
  const [role, setRole] = React.useState('users');
  const projectId = React.useContext(ProjectContext);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleInput = (e) => {
    setEmail(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  }

  const handleSubmit = () => {
    if (email) {
      console.log(`Setting ${email} to ${role}`);
      props.firebase.addUserToProject(projectId, email, role)
        .then(pendingUserEmail => {
          props.handleUserAddition(pendingUserEmail);
          handleClose();
        })
        .catch(error => { 
          setOpenAlert(true);
          setErrorMessage(error.message);
          console.log(error);
        });
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Add User
        </Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add New User</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To add a new user, please enter the new user's email address here.
              If this email address already has an account with InventoryTracker, it will be granted permissions to this project.
              If this user hasn't signed up yet, we will send this email address an invitation link so that the new user can sign up.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              onChange={handleInput}
            />
            <TextField
              id="standard-select-currency"
              select
              label="Select"
              value={role}
              onChange={handleRoleChange}
              helperText="Please select the role"
            >
            {props.firebase.userRoles.map((option) => (
              <MenuItem key={option.dbProperty} value={option.dbProperty}>
                {option.name}
              </MenuItem>
            ))}
            </TextField>
            <Collapse in={openAlert}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {errorMessage}
              </Alert>
            </Collapse>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </form>
  );

};

export default withFirebase(AddUserForm);
